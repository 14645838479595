import React, { useContext, useEffect, useState, useMemo } from 'react';
import { css } from 'styled-components';
import Cookies from 'js-cookie';
import SiteContext from '~/layouts/StoreContext';
import {
  getAssociationsByVariantId,
  getProductVariantInventory,
} from '~/helpers/requests/association-requests';
import { getShopifyCustomer } from '~/helpers/requests/customer-requests';
import './Checkout.scss';
import CheckoutForm from './CheckoutForm/CheckoutForm';
import CheckoutCart from './CheckoutCart/CheckoutCart';
import { Grid, Row, Col } from '~/components/.base/containers';
import { BodyText, HeadingLarge } from '~/components/.base/headings';
import Modal from '~/components/.base/modal';

const Checkout = ({ checkoutImages }) => {
  const {
    store: {
      client,
      checkout,
      checkout: { lineItems, subtotalPrice },
      trueLineItemsSubtotal,
    },
    addCustomer,
    removeLineItem,
  } = useContext(SiteContext);
  const [customer, setCustomer] = useState(null);
  const [subscriptionItems, setSubscriptionItems] = useState([]);
  const [shipping, setShipping] = useState(0);
  const [freeShipping, setFreeShipping] = useState(false);
  const [taxes, setTaxes] = useState(0);
  const [hasOnlyGCs, setHasOnlyGCs] = useState(false);
  const [registrationStatus, setRegistrationStatus] = useState(false);
  const customerAccessToken = Cookies.get('KEPT_SESS');
  const [paymentErrorMessage, setPaymentErrorMessage] = useState('');
  const [paymentErrorMessageDuration, setPaymentErrorMessageDuration] = useState(20);
  const [intervalId, setIntervalId] = useState(-1);
  const [appliedGiftCards, setAppliedGiftCards] = useState([]);
  const [giftCardPrices, setGiftCardPrices] = useState({});
  const [totalPrice, setTotalPrice] = useState(Number(subtotalPrice));
  const [trueLineItemsSubtotalWithGiftCards, setTrueLineItemsSubtotalWithGiftCards] =
    useState(trueLineItemsSubtotal);
  const [hasRanStockCheck, setHasRanStockCheck] = useState(false);
  const [lineItemsOutOfStock, setLineItemsOutOfStock] = useState(false);
  const hasApparel = useMemo(() => {
    for (let item of lineItems) {
      if (item.variant.sku.includes('APP-')) {
        return true;
      }
    }
    return false;
  }, [lineItems]);
  const isApparelOnly = useMemo(() => {
    let bool = true;
    for (let item of lineItems) {
      if (!item.variant.sku.includes('APP-')) {
        bool = false;
      }
    }
    return bool;
  }, [lineItems]);
  useEffect(() => {
    getShopifyCustomer(customerAccessToken).then(res => {
      if (res.data.data.customer) {
        setCustomer(res.data.data.customer);
        addCustomer(res.data.data.customer);
      }
    });
  }, [customerAccessToken]);
  useEffect(() => {
    setSubscriptionItems(
      lineItems.filter(item => {
        return item.title.toLowerCase().includes('subscription');
      })
    );
  }, [lineItems]);
  useEffect(() => {
    // if there's any subscription items, an account is necessary to keep track of them
    if (subscriptionItems.length) {
      setRegistrationStatus('NEEDS_ACCOUNT');
    }
    if (customerAccessToken) {
      setRegistrationStatus('HAS_ACCOUNT');
    }
  }, [subscriptionItems, customerAccessToken]);
  useEffect(() => {
    if (lineItems.length) {
      setHasOnlyGCs(() => {
        for (let item of lineItems) {
          if (!item.variant.sku || (item.variant.sku && !item.variant.sku.includes('GIFT'))) {
            return false;
          }
        }
        return true;
      });
    }
  }, [lineItems]);
  useEffect(() => {
    if (hasOnlyGCs || trueLineItemsSubtotal >= 250) {
      if (hasApparel) {
        setShipping(9.99);
      } else {
        setShipping(0);
      }
    } else if (trueLineItemsSubtotal >= 150) {
      if (hasApparel) {
        setShipping(29.98);
      } else {
        setShipping(19.99);
      }
    } else {
      if (hasApparel) {
        setShipping(44.99);
      } else {
        setShipping(35);
      }
    }
    if (isApparelOnly) {
      setShipping(9.99);
    }
  }, [hasOnlyGCs, trueLineItemsSubtotal, hasApparel]);
  useEffect(() => {
    // updates totals based on gift cards
    if (appliedGiftCards.length) {
      let sum = 0,
        unusedCredit;
      for (let gc of appliedGiftCards) {
        sum += gc.amount;
      }
      let gcPrice = Number(trueLineItemsSubtotal) + shipping + taxes - sum;
      if (gcPrice < 0) {
        unusedCredit = 0 - gcPrice;
        sum = trueLineItemsSubtotal + taxes + shipping;
        gcPrice = 0;
      }
      setGiftCardPrices({
        giftCardSum: sum,
        unusedCredit,
      });
      setTrueLineItemsSubtotalWithGiftCards(trueLineItemsSubtotal - sum);
    } else {
      setGiftCardPrices({});
      setTrueLineItemsSubtotalWithGiftCards(trueLineItemsSubtotal);
    }
  }, [appliedGiftCards, trueLineItemsSubtotal, shipping, taxes]);
  useEffect(() => {
    // sets total price based on shipping, taxes, totals
    if (trueLineItemsSubtotalWithGiftCards || trueLineItemsSubtotalWithGiftCards === 0) {
      function precisionRound(number, precision) {
        var factor = Math.pow(10, precision);
        return Math.round(number * factor) / factor;
      }
      setTotalPrice(precisionRound(trueLineItemsSubtotalWithGiftCards + shipping + taxes, 2));
    }
  }, [shipping, freeShipping, taxes, trueLineItemsSubtotal, trueLineItemsSubtotalWithGiftCards]);

  useEffect(() => {
    // stock-checking function: gets and caches availablities for all variants based on associations, etc.
    (async () => {
      let availabilityArr = [];
      if (hasRanStockCheck) return;
      if (lineItems.length) {
        setHasRanStockCheck(true);
      }
      try {
        for (let item of lineItems) {
          const variantInventoryData = await getProductVariantInventory(item.variant.id);
          const associationData = await getAssociationsByVariantId(item.variant.id);
          let associationQuantities = [];
          // necessary step for bundle products
          if (associationData.data.length) {
            for (let assn of associationData.data) {
              const associatedVariantInventoryData = await getProductVariantInventory(
                btoa(assn.associated_variant_id)
              );
              const { id, quantityAvailable } = associatedVariantInventoryData.data.data.node;
              associationQuantities.push({
                id,
                quantityAvailable,
              });
            }
          }
          availabilityArr.push({
            title: item.title,
            id: item.id,
            variantId: item.variant.id,
            quantityAvailable: variantInventoryData.data.data.node.quantityAvailable,
            associationQuantities,
          });
        }
        let hasOos = false;
        for (let availability of availabilityArr) {
          if (!availability.title.includes('Subscription') && availability.quantityAvailable <= 0) {
            hasOos = true;
            removeLineItem(client, checkout.id, availability.id);
            continue;
          }
          for (let association of availability.associationQuantities) {
            if (association.quantityAvailable <= 0) {
              hasOos = true;
              removeLineItem(client, checkout.id, availability.id);
            }
          }
        }
        if (hasOos) {
          setLineItemsOutOfStock(true);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [lineItems, hasRanStockCheck]);

  useEffect(() => {
    if (paymentErrorMessage.length < 1 && intervalId > 0) {
      clearInterval(intervalId);
      setIntervalId(-1);
    }
    if (intervalId < 0 && paymentErrorMessage.length > 0) {
      const id = setInterval(() => setPaymentErrorMessageDuration(old => old - 1), 1000);
      setPaymentErrorMessageDuration(20);
      setIntervalId(id);
    }
  }, [intervalId, paymentErrorMessage, paymentErrorMessageDuration]);

  useEffect(() => {
    if (paymentErrorMessageDuration < 1) {
      setPaymentErrorMessage('');
    }
  }, [paymentErrorMessageDuration]);

  return (
    <>
      <h1 className="sr-only">Checkout</h1>

      <Row className="checkout">
        {paymentErrorMessage.length ? (
          <div className="payment-error" role="alert">
            {paymentErrorMessage}
            <button
              className="paymentError__keep"
              onClick={() => setPaymentErrorMessageDuration(20)}
              style={{
                background: `conic-gradient(red ${
                  (paymentErrorMessageDuration / 20) * 100
                }%, white 0)`,
              }}
            >
              <div className="paymentError__keep-inner">{paymentErrorMessageDuration}</div>
            </button>
            <button className="paymentError__clear" onClick={() => setPaymentErrorMessage('')}>
              &times;
            </button>
          </div>
        ) : null}
        <Col size={8} className="checkout-form-container">
          <CheckoutForm
            customer={customer}
            setCustomer={setCustomer}
            subscriptionItems={subscriptionItems}
            registrationStatus={registrationStatus}
            freeShipping={freeShipping}
            appliedGiftCards={appliedGiftCards}
            giftCardPrices={giftCardPrices}
            totalPrice={totalPrice}
            taxes={taxes}
            setTaxes={setTaxes}
            shipping={shipping}
            setPaymentErrorMessage={setPaymentErrorMessage}
            hasOnlyGCs={hasOnlyGCs}
            hasApparel={hasApparel}
            isApparelOnly={isApparelOnly}
          />
        </Col>
        <Col size={4} className="checkout-cart">
          <CheckoutCart
            totalPrice={totalPrice}
            taxes={taxes}
            shipping={shipping}
            hasApparel={hasApparel}
            isApparelOnly={isApparelOnly}
            setFreeShipping={setFreeShipping}
            appliedGiftCards={appliedGiftCards}
            setAppliedGiftCards={setAppliedGiftCards}
            giftCardPrices={giftCardPrices}
            checkoutImages={checkoutImages}
            hasOnlyGCs={hasOnlyGCs}
          />
        </Col>
      </Row>
      <Modal
        open={lineItemsOutOfStock}
        onClose={() => setLineItemsOutOfStock(false)}
        ariaLabelledby="oos-modal-title"
        top="0"
        modalMaxWidth="700px"
      >
        <div id="oos-modal-title" className="oos-modal-title">
          <HeadingLarge>Warning</HeadingLarge>
        </div>
        <Grid className="oos-modal-body">
          <div>
            <BodyText md=".75rem 0 1.5rem">
              Some items in your cart are out of stock. They have been removed from your cart.
            </BodyText>
          </div>
        </Grid>
      </Modal>
    </>
  );
};

export default Checkout;
