import React, { Fragment } from 'react';
import Loader from 'react-loader-spinner';
import { Formik } from 'formik';
import { CheckoutInput } from '~/components/.base/inputs';
import { Grid, Row, Col, FlexBox } from '~/components/.base/containers';
import { RedButton, WhiteButton } from '~/components/.base/buttons';
import { BodyTextAlt } from '~/components/.base/headings';

const CheckoutRegister = ({ setAccountModalType, registerFn, registerError }) => {
    return (
        <div className="checkout-register">
            <Formik
                enableReinitialize={true}
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: '',
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validate={values => {
                    const errors = {};
                    console.log(values);
                    if (!values.firstName) {
                        errors.firstName = 'Required';
                    }
                    if (!values.lastName) {
                        errors.lastName = 'Required';
                    }
                    if (values.email === '') {
                        errors.email = 'Required';
                    } else if (
                        values.email &&
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address';
                    }
                    if (values.password === '') {
                        errors.password = 'Required';
                    }
                    console.log(errors);
                    return errors;
                }}
                onSubmit={registerFn}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    /* and other goodies */
                }) => (
                    <form className="address-form" onSubmit={handleSubmit}>
                        <FlexBox justify="space-between" direction="column" height="100%">
                            <Grid>
                                <Row>
                                    <Col size={1}>
                                        <CheckoutInput>
                                            <label htmlFor="firstName">First Name</label>
                                            <input
                                                name="firstName"
                                                className={`field${
                                                    !!errors.firstName ? ' is-invalid' : ''
                                                }`}
                                                placeholder="Enter your name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.firstName}
                                            />
                                            <div className="error">{errors.firstName}</div>
                                        </CheckoutInput>
                                    </Col>
                                    <Col size={1}>
                                        <CheckoutInput>
                                            <label htmlFor="lastName">Last Name</label>
                                            <input
                                                name="lastName"
                                                className={`field${
                                                    !!errors.lastName ? ' is-invalid' : ''
                                                }`}
                                                placeholder="Enter your last name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.lastName}
                                            />
                                            <div className="error">{errors.lastName}</div>
                                        </CheckoutInput>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col size={1}>
                                        <CheckoutInput>
                                            <label htmlFor="email">Email</label>
                                            <input
                                                name="email"
                                                type="email"
                                                className={`field full${
                                                    !!errors.email ? ' is-invalid' : ''
                                                }`}
                                                placeholder="Enter your email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                            />
                                            <div className="error">{errors.email}</div>
                                        </CheckoutInput>
                                    </Col>
                                </Row>{' '}
                                <Row>
                                    <Col size={1}>
                                        <CheckoutInput>
                                            <label htmlFor="password">Password</label>
                                            <input
                                                name="password"
                                                type="password"
                                                className={`field full${
                                                    !!errors.password ? ' is-invalid' : ''
                                                }`}
                                                placeholder="Enter your Account Password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password}
                                            />
                                            <div className="error">{errors.password}</div>
                                        </CheckoutInput>
                                    </Col>
                                </Row>
                            </Grid>
                            {registerError ? (
                                <div className="reg-error">
                                    <BodyTextAlt align="center" color="#d4212c">
                                        {registerError}
                                    </BodyTextAlt>
                                </div>
                            ) : null}
                            <FlexBox align="center" justify="flex-end">
                                <WhiteButton
                                    type="button"
                                    disabled={isSubmitting}
                                    h="50px"
                                    pd="0 35px"
                                    md="0 1.25rem"
                                    onClick={() => setAccountModalType('login')}
                                >
                                    Log In Instead
                                </WhiteButton>
                                <RedButton
                                    type="submit"
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                    h="50px"
                                    pd="0 35px"
                                    className="submit-btn"
                                >
                                    {isSubmitting ? (
                                        <Fragment>
                                            <Loader
                                                type="TailSpin"
                                                color="#152622"
                                                height={16}
                                                width={16}
                                            />
                                            Loading...
                                        </Fragment>
                                    ) : (
                                        'Sign Up'
                                    )}
                                </RedButton>
                            </FlexBox>
                        </FlexBox>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default CheckoutRegister;
