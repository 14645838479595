import React, { useState, useEffect, useContext } from "react";
import { graphql } from "gatsby";

import CheckoutForm from "~/components/Checkout/Checkout";
import SiteContext from "~/layouts/StoreContext";
import { Criteo } from "~/components/Criteo/Criteo";
import SEO from "../components/seo";
import { DeriveHeaderClasses } from "~/components/Navigation";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";

const CheckoutPage = ({ data, location }) => {
	const { checkoutImages } = data;
	const {
		store: {
			checkout: { lineItems },
		},
	} = useContext(SiteContext);
	const [stripe, setStripe] = useState(null);
	useEffect(() => {
		if (typeof window == "undefined") return;
		if (document) {
			if (document.querySelector("#launcher")) {
				document.querySelector("#launcher").style.display = "none";
			}
		}
		if (window.fbq) {
			window.fbq("track", "InitiateCheckout");
		}
		console.log(
			"process.env.GATSBY_STRIPE_PK",
			process.env.GATSBY_STRIPE_PK
		);
		const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK);
		setStripe(stripePromise);
	}, []);
	return stripe ? (
		<div>
			<SEO title="Checkout" />
			<Criteo />
			<DeriveHeaderClasses location={location} />
			<Elements stripe={stripe}>
				<CheckoutForm checkoutImages={checkoutImages} />
			</Elements>
		</div>
	) : null;
};

export const query = graphql`
	{
		checkoutImages: allFile(
			filter: {
				extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
				absolutePath: { regex: "/images/checkout/" }
			}
		) {
			edges {
				node {
					name
					childImageSharp {
						fluid(maxWidth: 1600, quality: 80) {
							aspectRatio
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		}
	}
`;

export default CheckoutPage;
