import React, { useState, useEffect, useContext, useRef } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import SiteContext from '~/layouts/StoreContext';
import Loader from 'react-loader-spinner';
import Cards from 'react-credit-cards';
import { css } from 'styled-components';
import { Formik } from 'formik';
import 'react-credit-cards/es/styles-compiled.css';
import { Col, FlexBox, RadioGroup } from '~/components/.base/containers';
import { CheckoutInput } from '~/components/.base/inputs';
import { RedButton, WhiteButton } from '~/components/.base/buttons';
import { Subheading } from '~/components/.base/headings';
import ArrowDownIcon from '~/images/icons/arrow_normal_down.svg';
import BillingAddressInputs from './BillingAddressInputs';
import './CardSection.scss';

const style = {
    base: {
        color: '#5c6566',
        iconColor: '#5c6566',
        fontFamily: 'Montserrat, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
            color: '#5c6566',
        },
    },
    invalid: {
        color: '#5c6566',
        iconColor: '#5c6566',
    },
};

const initialErrors = {
    firstName: null,
    lastName: null,
    email: null,
    address1: null,
    address2: null,
    city: null,
    provinceCode: null,
    zip: null,
    countryCodeV2: null,
};

const CardSection = ({
    cardName,
    setCardName,
    submitFn,
    registrationStatus,
    setActivePage,
    hasOnlyGCs,
}) => {
    const {
        store: {
            checkout: { lineItems },
        },
    } = useContext(SiteContext);
    const [issuer, setIssuer] = useState('');
    const [diffBilling, setDiffBilling] = useState('');
    // necessary to load google api for the address autocomplete
    const [googleApiLoaded, setGoogleApiLoaded] = useState(false);
    const [toggleSubmit, setToggleSubmit] = useState(false);
    const [errors, setErrors] = useState(initialErrors);

    const handleIsValidating = () => {
        setToggleSubmit(!toggleSubmit);
    };

    const cardNameFieldRef = useRef(null);

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        address1: '',
        address2: '',
        city: '',
        provinceCode: '',
        zip: '',
        countryCodeV2: 'US',
    };
    useEffect(() => {
        if (window && window.ga) {
            window.ga('require', 'ec');
            for (let lineItem of lineItems) {
                window.ga('ec:addProduct', {
                    name: lineItem.title,
                    id: lineItem.variant.id,
                    price: lineItem.variant.price.amount,
                    brand: 'CML',
                    variant: lineItem.variant.title,
                    quantity: lineItem.quantity,
                });
            }
            // Add the step number and additional info about the checkout to the action.
            window.ga('ec:setAction', 'checkout', {
                step: 'Payment',
            });
            window.ga('send', 'event', 'Checkout', 'Payment');
        }
    }, [lineItems]);

    const initialize = () => {
        console.log('check');
        if (window && window.google) {
            console.log('google');
            setGoogleApiLoaded(true);
        } else {
            console.log('no google');
            setTimeout(initialize, 500);
        }
    };
    useEffect(() => {
        if (hasOnlyGCs) {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBhG2W1tn5AUfDsx3u1OLm24_4flLCUtqA&libraries=places`;
            script.addEventListener('load', initialize);
            document.body.appendChild(script);
        }
    }, [hasOnlyGCs]);

    const cardsRef = useRef(null);

    useEffect(() => {
        if (cardsRef?.current?.base) {
            /* These elements are decorative and provides no information to users so we have to set an aria-hidden="true" attribute */
            cardsRef.current.base
                .querySelector('.rccs__card--front')
                .setAttribute('aria-hidden', true);
            cardsRef.current.base
                .querySelector('.rccs__card--back')
                .setAttribute('aria-hidden', true);
        }
    }, [cardsRef]);

    return (
        <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            validate={values => {
                const errs = {};

                if (!diffBilling && !hasOnlyGCs) return errs;

                if (!values.firstName) {
                    errs.firstName = 'Required';
                }
                if (!values.lastName) {
                    errs.lastName = 'Required';
                }
                if (!values.address1) {
                    errs.address1 = 'Required';
                }

                if (!values.city) {
                    errs.city = 'Required';
                }

                if (!values.provinceCode) {
                    errs.provinceCode = 'Required';
                }

                if (!values.zip) {
                    errs.zip = 'Required';
                }
                if (!values.countryCodeV2) {
                    errs.countryCodeV2 = 'Required';
                }
                console.log(errs);
                setErrors(errs);
                handleIsValidating();
                return errs;
            }}
            onSubmit={(values, { setSubmitting }) => {
                handleIsValidating();
                submitFn(values, diffBilling, setSubmitting);
            }}
        >
            {({
                values,
                errs,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                /* and other goodies */
            }) => (
                <form>
                    <FlexBox
                        direction="column"
                        className="card-section"
                        css={css`
                            @media screen and (min-width: 991px) {
                                height: calc(100vh - 190px - 91px);
                                overflow: scroll;
                                padding: 1rem 2.5rem 0;
                            }
                        `}
                    >
                        <FlexBox align="center" className="form-row">
                            <Col size={1}>
                                <Cards
                                    ref={cardsRef}
                                    cvc=""
                                    name={cardName}
                                    number=""
                                    expiry=""
                                    preview={true}
                                    issuer={issuer}
                                />
                            </Col>
                            <Col className="card-form" size={1}>
                                <CheckoutInput>
                                    <label>
                                        <span className="label">Card Number</span>
                                        <CardNumberElement
                                            className="card-element"
                                            options={{
                                                style: {
                                                    base: {
                                                        color: '#000',
                                                        iconColor: '#5c6566',
                                                        fontFamily: 'Montserrat, sans-serif',
                                                        fontSmoothing: 'antialiased',
                                                        fontSize: '16px',
                                                        '::placeholder': {
                                                            color: '#5c6566',
                                                        },
                                                    },
                                                    invalid: {
                                                        color: '#000',
                                                        iconColor: '#000',
                                                    },
                                                },
                                            }}
                                            onChange={c => {
                                                if (c.brand !== 'unknown') {
                                                    setIssuer(c.brand);
                                                } else {
                                                    setIssuer('');
                                                }
                                            }}
                                        />
                                    </label>
                                </CheckoutInput>
                                <CheckoutInput>
                                    <label htmlFor="cardName" className="label">
                                        Name On Card
                                    </label>
                                    <input
                                        id="cardName"
                                        type="text"
                                        placeholder="Enter Card Name"
                                        value={cardName}
                                        onChange={e => setCardName(e.target.value)}
                                        style={{ color: '#000' }}
                                        ref={cardNameFieldRef}
                                    />
                                </CheckoutInput>
                                <FlexBox>
                                    <CheckoutInput>
                                        <label>
                                            <span className="label">Valid Thru</span>
                                            <CardExpiryElement
                                                className="card-element"
                                                style={style}
                                                onChange={c => console.log(c)}
                                            />
                                        </label>
                                    </CheckoutInput>
                                    <CheckoutInput>
                                        <label>
                                            <span className="label">CVC</span>
                                            <CardCvcElement
                                                className="card-element"
                                                style={style}
                                                onChange={c => console.log(c)}
                                            />
                                        </label>
                                    </CheckoutInput>
                                </FlexBox>
                            </Col>
                        </FlexBox>

                        <FlexBox
                            direction="column"
                            justify="space-between"
                            className="card-section-lower"
                        >
                            <div className="billing-address">
                                <Subheading>Billing Address</Subheading>
                                {hasOnlyGCs ? (
                                    googleApiLoaded ? (
                                        <div className="billing-address-cont">
                                            <BillingAddressInputs
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                errors={errors}
                                                touched={touched}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                registrationStatus={registrationStatus}
                                                toggleSubmit={toggleSubmit}
                                            />
                                        </div>
                                    ) : null
                                ) : (
                                    <RadioGroup as="fieldset">
                                        <legend class="sr-only">Choose a billing address</legend>
                                        <FlexBox
                                            as="label"
                                            htmlFor="checkout_different_billing_address_false"
                                            className="radio-item"
                                            onClick={() => setDiffBilling(false)}
                                        >
                                            <input
                                                className="input-radio"
                                                type="radio"
                                                value="false"
                                                id="checkout_different_billing_address_false"
                                                checked={!diffBilling}
                                                name="checkout[different_billing_address]"
                                            />
                                            <div className="text">
                                                <Subheading className="centered">
                                                    Same as shipping address
                                                </Subheading>
                                            </div>
                                        </FlexBox>
                                        <FlexBox
                                            as="label"
                                            htmlFor="checkout_different_billing_address_true"
                                            className="radio-item"
                                            onClick={() => setDiffBilling(true)}
                                        >
                                            <input
                                                className="input-radio"
                                                type="radio"
                                                value="true"
                                                id="checkout_different_billing_address_true"
                                                checked={diffBilling}
                                                name="checkout[different_billing_address]"
                                            />
                                            <div className="text">
                                                <Subheading className="centered">
                                                    Use a different billing address
                                                </Subheading>
                                            </div>
                                        </FlexBox>
                                        {diffBilling ? (
                                            <BillingAddressInputs
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                errors={errors}
                                                touched={touched}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                registrationStatus={registrationStatus}
                                                toggleSubmit={toggleSubmit}
                                            />
                                        ) : null}
                                    </RadioGroup>
                                )}
                            </div>
                        </FlexBox>
                    </FlexBox>
                    <FlexBox align="center" justify="space-between" className="form-footer">
                        <FlexBox
                            as="button"
                            type="button"
                            onClick={() => setActivePage(2)}
                            align="center"
                            className="back-button desktop"
                        >
                            <WhiteButton as="span" w="50px" h="50px" pd="0px">
                                <ArrowDownIcon
                                    style={{
                                        transform: 'rotate(90deg)',
                                    }}
                                />
                            </WhiteButton>
                            <Subheading as="span" md="0rem 0rem 0rem .75rem">
                                Return to Shipping
                            </Subheading>
                        </FlexBox>
                        <WhiteButton
                            type="button"
                            onClick={() => setActivePage(2)}
                            className="back-button mobile"
                        >
                            Return to Shipping
                        </WhiteButton>
                        <div className="button-bg" />
                        <RedButton
                            onClick={handleSubmit}
                            type="submit"
                            h="50px"
                            pd="0 35px"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? (
                                <>
                                    <Loader
                                        type="TailSpin"
                                        color="#152622"
                                        height={16}
                                        width={16}
                                    />
                                    Loading...
                                </>
                            ) : (
                                'Buy Now!'
                            )}
                        </RedButton>
                    </FlexBox>
                </form>
            )}
        </Formik>
    );
};

export default CardSection;
