import React, { useState, useEffect, useRef, useCallback } from "react";
import { CheckoutInput, CheckoutSelect } from "~/components/.base/inputs";
import { Grid, Row, Col } from "~/components/.base/containers";
import ArrowDown from "~/images/icons/arrow_normal_down.svg";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress } from "react-places-autocomplete";

const BillingAddressInputs = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  registrationStatus,
  setFieldValue,
  toggleSubmit
}) => {
  const [provinceCode, setProvinceCode] = useState("AK");
  const [countryCodeV2, setCountryCodeV2] = useState("US");
  const [address1, setAddress1] = useState("");
  const stateLists = {
    US: {
      AK: "Alaska",
      AL: "Alabama",
      AR: "Arkansas",
      AZ: "Arizona",
      CA: "California",
      CO: "Colorado",
      CT: "Connecticut",
      DC: "District of Columbia",
      DE: "Delaware",
      FL: "Florida",
      GA: "Georgia",
      HI: "Hawaii",
      IA: "Iowa",
      ID: "Idaho",
      IL: "Illinois",
      IN: "Indiana",
      KS: "Kansas",
      KY: "Kentucky",
      LA: "Louisiana",
      MA: "Massachusetts",
      MD: "Maryland",
      ME: "Maine",
      MI: "Michigan",
      MN: "Minnesota",
      MO: "Missouri",
      MS: "Mississippi",
      MT: "Montana",
      NC: "North Carolina",
      ND: "North Dakota",
      NE: "Nebraska",
      NH: "New Hampshire",
      NJ: "New Jersey",
      NM: "New Mexico",
      NV: "Nevada",
      NY: "New York",
      OH: "Ohio",
      OK: "Oklahoma",
      OR: "Oregon",
      PA: "Pennsylvania",
      PR: "Puerto Rico",
      RI: "Rhode Island",
      SC: "South Carolina",
      SD: "South Dakota",
      TN: "Tennessee",
      TX: "Texas",
      UT: "Utah",
      VA: "Virginia",
      VT: "Vermont",
      WA: "Washington",
      WI: "Wisconsin",
      WV: "West Virginia",
      WY: "Wyoming"
    },
    CA: {
      AB: "Alberta",
      BC: "British Columbia",
      MB: "Manitoba",
      NB: "New Brunswick",
      NL: "Newfoundland",
      NS: "Nova Scotia",
      NT: "Northwest Territories",
      NU: "Nunavut",
      ON: "Ontario",
      PE: "Prince Edward Island",
      QC: "Quebec",
      SK: "Saskatchewan",
      YT: "Yukon"
    }
  };

  const firstNameFieldRef = useRef(null);
  const lastNameFieldRef = useRef(null);
  const address1FieldRef = useRef(null);
  const cityFieldRef = useRef(null);
  const provinceCodeFieldRef = useRef(null);
  const zipFieldRef = useRef(null);
  const countryCodeV2FieldRef = useRef(null);

  const syncStateToFormik = () => {
    values.provinceCode = provinceCode;
    values.countryCodeV2 = countryCodeV2;
    values.address1 = address1;
  };
  useEffect(syncStateToFormik, [values, provinceCode, countryCodeV2, address1]);
  const handleChangeAddress1 = (a) => {
    setAddress1(a);
  };

  const handleSelect = (address, setFieldValue) => {
    geocodeByAddress(address).then((results) => {
      const { address_components, formatted_address } = results[0];
      setAddress1(formatted_address.split(", ")[0]);
      setFieldValue(
        "city",
        address_components.find((c) => c.types.includes("locality")).long_name
      );
      setFieldValue(
        "zip",
        address_components.find((c) => c.types.includes("postal_code"))
          .long_name
      );
      setProvinceCode(
        address_components.find((c) =>
          c.types.includes("administrative_area_level_1")
        ).short_name
      );
      setCountryCodeV2(
        address_components.find((c) => c.types.includes("country")).short_name
      );
    });
  };

  const handleFocusInvalidField = useCallback(() => {
    console.log("handleFocusInvalidField");
    if (errors.firstName && firstNameFieldRef?.current) {
      firstNameFieldRef.current.focus();
      return;
    }
    if (errors.lastName && lastNameFieldRef?.current) {
      lastNameFieldRef.current.focus();
      return;
    }
    if (errors.address1 && address1FieldRef?.current) {
      address1FieldRef.current.focus();
      return;
    }

    if (errors.city && cityFieldRef?.current) {
      cityFieldRef.current.focus();
      return;
    }

    if (errors.provinceCode && provinceCodeFieldRef?.current) {
      provinceCodeFieldRef.current.focus();
      return;
    }

    if (errors.zip && zipFieldRef?.current) {
      zipFieldRef.current.focus();
      return;
    }

    if (errors.countryCodeV2 && countryCodeV2FieldRef?.current) {
      countryCodeV2FieldRef.current.focus();
      return;
    }
  }, [
    errors,
    firstNameFieldRef,
    lastNameFieldRef,
    address1FieldRef,
    cityFieldRef,
    provinceCodeFieldRef,
    zipFieldRef,
    countryCodeV2FieldRef
  ]);
  useEffect(() => {
    handleFocusInvalidField();
  }, [toggleSubmit, errors]);
  return (
    <div className="billing-form">
      <Grid>
        <Row className="form-row">
          <Col size={1}>
            <CheckoutInput>
              <label htmlFor="firstName">First Name</label>
              <input
                id="firstName"
                name="firstName"
                className={`field${!!errors.firstName ? " is-invalid" : ""}`}
                placeholder="Enter your name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                ref={firstNameFieldRef}
                aria-describedby="firstNameError"
                autoComplete="given-name"
              />
              <div id="firstNameError" className="error">
                {errors.firstName}
              </div>
            </CheckoutInput>
          </Col>
          <Col size={1}>
            <CheckoutInput>
              <label htmlFor="lastName">Last Name</label>
              <input
                id="lastName"
                name="lastName"
                className={`field${!!errors.lastName ? " is-invalid" : ""}`}
                placeholder="Enter your last name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                ref={lastNameFieldRef}
                aria-describedby="lastNameError"
                autoComplete="family-name"
              />
              <div id="lastNameError" className="error">
                {errors.lastName}
              </div>
            </CheckoutInput>
          </Col>
        </Row>
        <Row className="form-row">
          <Col size={1}>
            <CheckoutInput>
              <label htmlFor="address1" className="label">
                Address 1
              </label>
              <PlacesAutocomplete
                value={address1}
                onChange={handleChangeAddress1}
                onSelect={(address) => handleSelect(address, setFieldValue)}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading
                }) => (
                  <CheckoutInput>
                    <input
                      id="address1"
                      {...getInputProps({
                        placeholder: "Search Places ...",
                        className: `location-search-input${
                          !!errors.address1 ? " is-invalid" : ""
                        }`
                      })}
                      ref={address1FieldRef}
                      aria-describedby="address1Error"
                      autoComplete="street-address"
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer"
                            }
                          : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer"
                            };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                    <div id="address1Error" className="error">
                      {errors.address1}
                    </div>
                  </CheckoutInput>
                )}
              </PlacesAutocomplete>
            </CheckoutInput>
          </Col>
          <Col size={1}>
            <CheckoutInput>
              <label htmlFor="address2">Address 2</label>
              <input
                name="address2"
                className={`field${!!errors.address2 ? " is-invalid" : ""}`}
                placeholder="Enter your address"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.address2}
                aria-describedby="address2Error"
              />
              <div id="address2Error" className="error">
                {errors.address2}
              </div>
            </CheckoutInput>
          </Col>
        </Row>
        <Row className="form-row">
          <Col size={1}>
            <CheckoutInput>
              <label htmlFor="city">City</label>
              <input
                id="city"
                name="city"
                className={`field${!!errors.city ? " is-invalid" : ""}`}
                placeholder="Enter your city"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.city}
                ref={cityFieldRef}
                aria-describedby="cityError"
                autoComplete="address-level2"
              />
              <div className="error">{errors.city}</div>
            </CheckoutInput>
          </Col>
          <Col size={1}>
            <CheckoutSelect
              id="state"
              className={!!errors.provinceCode ? " is-invalid" : ""}
            >
              <label htmlFor="provinceCode">State</label>
              <select
                id="provinceCode"
                name="provinceCode"
                placeholder="State"
                onBlur={(e) => setProvinceCode(e.target.value)}
                onChange={(e) => setProvinceCode(e.target.value)}
                value={provinceCode}
                disabled={
                  !values.countryCodeV2 === "US" &&
                  !values.countryCodeV2 === "CA"
                }
                ref={provinceCodeFieldRef}
                aria-describedby="provinceCodeError"
                autoComplete="address-level1"
              >
                {values.countryCodeV2 === "US" ||
                values.countryCodeV2 === "CA" ? (
                  Object.entries(stateLists[values.countryCodeV2]).map(
                    (state, i) => (
                      <option key={i} value={state[0]}>
                        {state[1]}
                      </option>
                    )
                  )
                ) : (
                  <option value="">State</option>
                )}
              </select>
              <ArrowDown style={{ pointerEvents: "none" }} />
              <div id="provinceCodeError" className="error">
                {errors.provinceCode}
              </div>
            </CheckoutSelect>
          </Col>
        </Row>
        <Row className="form-row">
          <Col size={1}>
            <CheckoutInput>
              <label htmlFor="zip">Zip Code</label>
              <input
                id="zip"
                name="zip"
                className={`field${!!errors.zip ? " is-invalid" : ""}`}
                placeholder="Enter your ZIP Code"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.zip}
                ref={zipFieldRef}
                aria-describedby="zipError"
                autoComplete="postal-code"
              />
              <div id="zipError" className="error">
                {errors.zip}
              </div>
            </CheckoutInput>
          </Col>
          <Col size={1}>
            <CheckoutSelect
              id="country"
              className={!!errors.countryCodeV2 ? " is-invalid" : ""}
            >
              <label htmlFor="countryCodeV2">Country</label>
              <select
                id="countryCodeV2"
                name="countryCodeV2"
                placeholder="Country"
                onBlur={(e) => setProvinceCode(e.target.value)}
                onChange={(e) => setCountryCodeV2(e.target.value)}
                value={countryCodeV2}
                autoComplete="country-name"
              >
                <option value="AU">Australia</option>
                <option value="AT">Austria</option>
                <option value="BE">Belgium</option>
                <option value="BR">Brazil</option>
                <option value="CA">Canada</option>
                <option value="CN">China</option>
                <option value="DK">Denmark</option>
                <option value="FI">Finland</option>
                <option value="FR">France</option>
                <option value="DE">Germany</option>
                <option value="HK">Hong Kong</option>
                <option value="IE">Ireland</option>
                <option value="IT">Italy</option>
                <option value="JP">Japan</option>
                <option value="LU">Luxembourg</option>
                <option value="MY">Malaysia</option>
                <option value="MX">Mexico</option>
                <option value="NL">Netherlands</option>
                <option value="NZ">New Zealand</option>
                <option value="NO">Norway</option>
                <option value="PT">Portugal</option>
                <option value="SG">Singapore</option>
                <option value="ES">Spain</option>
                <option value="SE">Sweden</option>
                <option value="CH">Switzerland</option>
                <option value="GB">United Kingdom</option>
                <option value="US">United States</option>
              </select>
              <ArrowDown style={{ pointerEvents: "none" }} />
            </CheckoutSelect>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default BillingAddressInputs;
