import React, { useState, useEffect, useContext } from 'react';
import SiteContext from '~/layouts/StoreContext';
import axios from 'axios';
import { Formik } from 'formik';
import Loader from 'react-loader-spinner';
import { css } from 'styled-components';
import 'date-fns';
import { months, weekDays } from '~/helpers';
import { FlexBox, CenteredContainer } from '~/components/.base/containers';
import { Subheading, Heading, BodyText } from '~/components/.base/headings';
import { WhiteButton, RedButton } from '~/components/.base/buttons';
import ArrowDownIcon from '~/images/icons/arrow_normal_down.svg';
import StreamlineIcon from '~/images/icons/checkout/shipping_plane.svg';
import SuccessIcon from '~/images/icons/checkout/calendar_check.svg';
import moment from 'moment';

import './ShippingSection.scss';

import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { getBlackoutDates } from '~/helpers/requests/blackout-dates-requests';

const ShippingCalculator = ({
  addressValues,
  shipping,
  shipByDate,
  setShipByDate,
  setActivePage,
  isApparelOnly,
}) => {
  const {
    store: {
      checkout: { lineItems },
    },
  } = useContext(SiteContext);
  const [minShippingDate, setMinShippingDate] = useState(null);
  const [alternativeDate, setAlternativeDate] = useState(null);
  const [upsError, setUpsError] = useState(null);
  const [arrivalDate, setArrivalDate] = useState(null);
  // const [shippingError, setShippingError] = useState("");
  const [dateFound, setDateFound] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [blackoutDates, setBlackoutDates] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await getBlackoutDates();
      if (!res && !res?.data) {
        console.log('[blackoutDatesError]', res);
      }
      setBlackoutDates(res.data);
    })();
  }, []);
  useEffect(() => {
    if (isApparelOnly) {
      setShipByDate(new Date());
    }
  }, [isApparelOnly]);
  useEffect(() => {
    // enhanced ecommerce tracking
    if (window && window.ga) {
      window.ga('require', 'ec');
      for (let lineItem of lineItems) {
        window.ga('ec:addProduct', {
          name: lineItem.title,
          id: lineItem.variant.id,
          price: lineItem.variant.price.amount,
          brand: 'CML',
          variant: lineItem.variant.title,
          quantity: lineItem.quantity,
        });
      }
      // Add the step number and additional info about the checkout to the action.
      window.ga('ec:setAction', 'checkout', {
        step: 'Shipping',
      });
      window.ga('send', 'event', 'Checkout', 'Shipping');
    }
  }, [lineItems]);
  useEffect(() => {
    const d = new Date();
    d.setDate(d.getDate() + 1);
    const decodedItems = lineItems.map(item => item.variant.product.id);
    // conspire dashboard allows CML to set custom cut-off times for each product
    axios
      .get(
        `https://cml-embedded-gc-subscriptions.herokuapp.com/api/cut-off-times/check?products=${decodedItems.join()}`
      )
      .then(res => {
        if (res?.data?.hour) {
          const cutoff = new Date();
          const isDST = new Date().getMonth() >= 2 && new Date().getMonth() <= 10;
          const offset = isDST ? 4 : 5;
          cutoff.setUTCHours(res?.data?.hour + offset);
          cutoff.setUTCMinutes(res?.data?.minute);
          if (
            cutoff.getHours() < new Date().getHours() ||
            (cutoff.getHours() === new Date().getHours() &&
              cutoff.getMinutes() < new Date().getMinutes())
          ) {
            d.setDate(d.getDate() + 1);
            while (isBlackoutDate(d) || d.getDay() < 2) {
              d.setDate(d.getDate() + 1);
            }
          }
        } else {
          // if no cut-off time specified, just set the first available date two days in the future
          d.setDate(d.getDate() + 1);
          while (isBlackoutDate(d) || d.getDay() < 2) {
            d.setDate(d.getDate() + 1);
          }
        }

        setMinShippingDate(d);
        setInitialValues({ date: d });
        initCalendar(d);
      })
      .catch(err => {
        console.log(err);
        setMinShippingDate(d);
        setInitialValues({ date: d });
        initCalendar(d);
      });
  }, [blackoutDates]);

  const initCalendar = minShippingDate => {
    let dateNow = Date.now();
    let today = new Date();
    let thisYear = today.getFullYear();
    let thisMonth = today.getMonth();

    function thanksgiving(year) {
      let lastOfNov = new Date(year, 10, 30).getDay();
      let thanksgiving = (lastOfNov >= 4 ? 34 : 27) - lastOfNov;
      return thanksgiving;
    }

    function isHoliday(date) {
      return (
        (date.getMonth() === 0 && date.getDate() === 1) ||
        (date.getMonth() === 6 && date.getDate() === 4) ||
        (date.getMonth() === 10 && date.getDate() === thanksgiving(date.getFullYear())) ||
        (date.getMonth() === 11 && date.getDate() === 24) ||
        (date.getMonth() === 11 && date.getDate() === 25) ||
        (date.getMonth() === 11 && date.getDate() === 31)
      );
    }

    function dateDisableFunc(date) {
      const d = new Date(date);
      return isHoliday(d) || window.isBlackoutDate(d) || d.getDay() < 2;
    }

    function monthDisableFunc(year, month) {
      return thisYear > year || (thisYear === year && thisMonth + 1 > month);
    }

    function yearDisableFunc(year) {
      return thisYear > year;
    }

    $('.date').datepicker({
      inputFormat: 'MM/dd/yyyy',
      outputFormat: 'MM/dd/yyyy',
      isDateDisabled: dateDisableFunc,
      isMonthDisabled: monthDisableFunc,
      isYearDisabled: yearDisableFunc,
      gainFocusOnConstruction: false,
    });

    $('.date').datepicker('min', moment(minShippingDate).format('MM/DD/YYYY'));

    console.log('calendar plugin initialized');
  };

  const handleDateChange = async (d, setFieldValue) => {
    setDateFound(false);
    setUpsError(null);
    setAlternativeDate(null);
    setFieldValue('date', d);
  };
  const handleValidate = async (values, setFieldValue) => {
    console.log('handleValidate', values.date);
    const d = new Date(values.date);
    const minDate = new Date(minShippingDate);

    if (
      minDate.getFullYear() > d.getFullYear() ||
      (minDate.getFullYear() === d.getFullYear() && minDate.getMonth() > d.getMonth()) ||
      (minDate.getMonth() === d.getMonth() &&
        minDate.getFullYear() === d.getFullYear() &&
        minDate.getDate() > d.getDate())
    ) {
      setUpsError(new Error('Unfortunately, the delivery date you chose is not available.'));
      throw new Error('Unfortunately, the delivery date you chose is not available.');
    }
    if (isBlackoutDate(d)) {
      setUpsError(new Error('Unfortunately, the delivery date you chose is not available.'));
      throw new Error('Unfortunately, the delivery date you chose is not available. ');
    }
    let att = 0,
      foundDate = false;
    d.setHours(1);
    // setShippingError("");
    // will search for a date up to 7 times after the initial date, att is how many days after the selected date
    while (att < 7 && !foundDate) {
      const formattedDate = d.toISOString().slice(0, 10).split('-').join('');
      try {
        const shippingResp = await getShippingForDate(formattedDate);
        if (shippingResp.data[formattedDate].length) {
          foundDate = true;
          if (att > 0) {
            setAlternativeDate(d);
            setUpsError(true);
            throw new Error(
              "We couldn't find shipping for that date, here is the nearest possible replacement: " +
                d.toISOString().slice(0, 10)
            );
          }
        }
      } catch (err) {
        console.log(err.response);
        // handling for weird UPS errors that we were getting at one point
        if (err.response && err.response.status === 500) {
          console.log('setting ups error');
          setUpsError(
            new Error(
              'We are having some trouble determining whether we can deliver to your zip code. Please contact us.'
            )
          );
        } else {
          setUpsError(err);
        }
        throw new Error(err.message);
      }
      var isSunMon = true;
      // skips weekends
      while (isSunMon) {
        d.setDate(d.getDate() + 1);
        if (d.getDay() > 1) isSunMon = false;
      }
      att++;
    }
    if (!foundDate) {
      setUpsError(
        new Error('Unfortunately, the delivery date you chose is not available for your address.')
      );
      throw new Error(
        'Unfortunately, the delivery date you chose is not available for your address.'
      );
    }
  };
  const handleSubmit = async (values, { setSubmitting, setFieldValue }) => {
    const d = new Date(values.date);
    try {
      await handleValidate(values, setFieldValue);
      setArrivalDate(new Date(d));
      d.setDate(d.getDate() - 1);
      setShipByDate(d);
      setDateFound(true);
    } catch (err) {
      console.log(err);
      // setShippingError(err.message);
      setSubmitting(false);
    }
  };
  const handlePageChange = async () => {
    window.scrollTo(0, 0);
    setActivePage(3);
  };
  const getShippingForDate = formattedDate => {
    const { provinceCode, countryCodeV2, zip, city } = addressValues;
    const data = {
      stateProvinceCode: provinceCode,
      countryCode: countryCodeV2,
      postalCode: zip,
      arrivalDate: formattedDate,
      city,
    };
    return axios.post(`${process.env.GATSBY_SERVER_API_URL}/shipping/next-day-options`, data);
  };
  const isBlackoutDate = d => {
    if (blackoutDates?.length) {
      return blackoutDates.some(b => {
        // console.log(blackoutDates);
        const date = new Date(b.date);
        if (b.option === 'YEARLY') {
          return d.getMonth() === date.getMonth() && d.getDate() === date.getDate();
        } else if (b.option === 'ONETIME') {
          // console.log(
          //   d,
          //   date,
          //   'is blackout onetime',
          //   d.getFullYear() === date.getFullYear() &&
          //     d.getMonth() === date.getMonth() &&
          //     d.getDate() === date.getDate()
          // );
          return (
            d.getFullYear() === date.getFullYear() &&
            d.getMonth() === date.getMonth() &&
            d.getDate() === date.getDate()
          );
        } else {
          return false;
        }
      });
    } else {
      return false;
    }
  };
  window.isBlackoutDate = isBlackoutDate;

  const formatDate = value => {
    return moment(value).format('MM/DD/YYYY');
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validate={values => {
        return {};
      }}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <>
          <FlexBox
            direction="column"
            className="shipping-section"
            css={css`
              @media screen and (min-width: 991px) {
                height: calc(100vh - 190px - 91px);
                overflow: scroll;
                padding: 1rem 2.5rem 0;
              }
            `}
          >
            <div className="shipping-section-upper">
              <FlexBox className="upper-item" justify="space-between">
                <FlexBox className="mobile-col">
                  <div className="grey text">Email</div>
                  <div className="text">{addressValues.email}</div>
                </FlexBox>
                <button as="button" className="change text" onClick={() => setActivePage(1)}>
                  Change
                </button>
              </FlexBox>
              <FlexBox className="upper-item" justify="space-between">
                <FlexBox className="mobile-col">
                  <div className="grey text">Ship To</div>
                  <div className="text">{`${addressValues.address1} ${addressValues.address2}, ${addressValues.city}, ${addressValues.provinceCode} ${addressValues.zip}`}</div>
                </FlexBox>
                <button className="change text" onClick={() => setActivePage(1)}>
                  Change
                </button>
              </FlexBox>
              <FlexBox className="upper-item" justify="space-between">
                <FlexBox className="mobile-col">
                  <div className="grey text">Method</div>
                  <div className="text">{isApparelOnly ? 'UPS Ground' : 'UPS Next Day Air'}</div>
                </FlexBox>
              </FlexBox>
            </div>

            {isApparelOnly ? (
              <BodyText>
                Since this order only contains apparel, we will ship it via UPS Ground as soon as
                possible.
              </BodyText>
            ) : (
              <FlexBox
                direction="column"
                justify="space-between"
                className="shipping-section-lower"
              >
                <div>
                  <FlexBox justify="space-between" className="header-row">
                    <Heading>Select your delivery date</Heading>
                  </FlexBox>
                  <div className="date-cont-outer">
                    {initialValues.date && minShippingDate ? (
                      <FlexBox justify="center" align="center" className="date-cont">
                        <CenteredContainer className="date-cont-inner">
                          {dateFound && arrivalDate ? <SuccessIcon /> : <StreamlineIcon />}
                          <div className="datePickerWrapper">
                            <input
                              className="date form-control"
                              id="date1"
                              type="text"
                              value={values.date && formatDate(values.date)}
                              placeholder="MM/dd/yyyy"
                              onFocus={e => {
                                console.log('onFocus e.target.value', new Date(e.target.value));
                                handleDateChange(e.target.value, setFieldValue);
                              }}
                              onBlur={e => {
                                console.log('onBlur e.target.value', new Date(e.target.value));
                                handleDateChange(e.target.value, setFieldValue);
                              }}
                            />
                          </div>
                          {upsError ? (
                            alternativeDate ? (
                              <>
                                <BodyText color="#d4212c">
                                  We can't ship to your location for that date. Here is the closest
                                  alternative date:
                                </BodyText>
                                <Heading>
                                  <span className="red">
                                    {`${weekDays[alternativeDate.getDay()]},`}
                                  </span>
                                  <br />
                                  <span>
                                    {`${
                                      months[alternativeDate.getMonth()]
                                    }  ${alternativeDate.getDate()}, ${alternativeDate.getFullYear()}`}{' '}
                                  </span>
                                </Heading>
                              </>
                            ) : (
                              <>
                                <BodyText>{upsError.message}</BodyText>
                              </>
                            )
                          ) : dateFound && arrivalDate ? (
                            <div role="status">
                              <BodyText>Great! Your order will arrive on:</BodyText>
                              <Heading align="center">
                                <span className="red">{`${weekDays[arrivalDate.getDay()]},`}</span>
                                <br />
                                <span>
                                  {`${
                                    months[arrivalDate.getMonth()]
                                  }  ${arrivalDate.getDate()}, ${arrivalDate.getFullYear()}`}{' '}
                                </span>
                              </Heading>
                            </div>
                          ) : (
                            <>
                              <BodyText>
                                All Seafood ships overnight the day before delivery date, while
                                apparel items ship via ground the day before delivery date.
                              </BodyText>
                              <WhiteButton onClick={handleSubmit} disabled={isSubmitting}>
                                {isSubmitting ? (
                                  <>
                                    <Loader type="TailSpin" height={16} width={16} />
                                    Loading...
                                  </>
                                ) : (
                                  'Select this date'
                                )}
                              </WhiteButton>
                            </>
                          )}
                        </CenteredContainer>
                      </FlexBox>
                    ) : null}
                  </div>
                </div>
              </FlexBox>
            )}
          </FlexBox>
          <FlexBox align="center" justify="space-between" className="form-footer">
            <FlexBox
              as="button"
              onClick={() => setActivePage(1)}
              align="center"
              className="back-button desktop"
            >
              <WhiteButton as="span" w="50px" height="50px" pd="0px">
                <ArrowDownIcon
                  style={{
                    transform: 'rotate(90deg)',
                  }}
                />
              </WhiteButton>
              <Subheading as="span" md="0rem 0rem 0rem .75rem">
                Return to information
              </Subheading>
            </FlexBox>
            <WhiteButton onClick={() => setActivePage(1)} className="back-button mobile">
              Return to information
            </WhiteButton>
            <div className="button-bg" />
            <RedButton
              type="submit"
              onClick={handlePageChange}
              disabled={!dateFound && !isApparelOnly}
              h="50px"
              pd="0 35px"
            >
              {isSubmitting ? (
                <>
                  <Loader type="TailSpin" height={16} width={16} />
                  Loading...
                </>
              ) : (
                'Continue to Payment'
              )}
            </RedButton>
          </FlexBox>
        </>
      )}
    </Formik>
  );
};

export default ShippingCalculator;
