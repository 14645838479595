import React, { useState, useEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import SiteContext from '~/layouts/StoreContext';
import Image from 'gatsby-image';
import { useStripe, useElements } from '@stripe/react-stripe-js';

// Hooks
import { useBackground } from '../../../hooks/use-background';

import {
    getPaymentIntent,
    getSetupIntent,
    createStripeCustomer,
    addOrderIdsToCustomer,
} from '~/helpers/requests/stripe-requests';
import {
    createShopifyOrder,
    calculateShopifyOrder,
    redeemGiftcards,
    createCustomer,
    completeShopifyOrder,
    sendDiscountCodeData,
} from '~/helpers/requests/checkout-requests';
import { getImage } from '~/helpers';
import AddressForm from '../AddressForm/AddressForm';
import CardSection from '../CardSection/CardSection';
import ShippingSection from '../ShippingSection/ShippingSection';
import DotTrailIcon from '~/images/icons/checkout/dot-trail.svg';
import CustInfoIcon from '~/images/icons/checkout/customer-information.svg';
import PaymentIcon from '~/images/icons/checkout/payment.svg';
import ShippingIcon from '~/images/icons/checkout/shipping.svg';
import { Subheading } from '~/components/.base/headings';
import { FlexBox, BackgroundDiv } from '~/components/.base/containers';
import './CheckoutForm.scss';
import { css } from 'styled-components';

const CheckoutForm = ({
    customer,
    setCustomer,
    subscriptionItems,
    registrationStatus,
    freeShipping,
    appliedGiftCards,
    giftCardPrices,
    totalPrice,
    shipping,
    taxes,
    setTaxes,
    setPaymentErrorMessage,
    hasOnlyGCs,
    isApparelOnly,
}) => {
    const bgImages = useBackground();
    const {
        store: {
            client,
            checkout: { discountApplications, lineItems, lineItemsSubtotalPrice, subtotalPrice },
            trueLineItemsSubtotal,
            trueLineItemsSubtotalNoDiscounts,
            trueLineItemsBasePriceSubtotalNoDiscountsSubscriptionsIncluded,
            giftNote,
        },
        updateShippingAddress,
    } = useContext(SiteContext);
    const stripe = useStripe();
    const elements = useElements();
    const [activePage, setActivePage] = useState(1);
    const [addressValues, setAddressValues] = useState({});
    const [shipByDate, setShipByDate] = useState(null);
    const [cardName, setCardName] = useState('');
    useEffect(() => {
        console.log('hasOnlyGCs', hasOnlyGCs);
        if (hasOnlyGCs) {
            setActivePage(3);
        }
    }, [hasOnlyGCs]);

    useEffect(() => {
        // CALCULATES TAXES
        if (
            addressValues?.address1 &&
            addressValues?.city &&
            addressValues?.provinceCode &&
            lineItems?.length &&
            trueLineItemsSubtotal
        )
            (async () => {
                let totalDiscount =
                    trueLineItemsBasePriceSubtotalNoDiscountsSubscriptionsIncluded -
                    trueLineItemsSubtotal;
                const trueDiscountApplications = [
                    {
                        value: {
                            amount: `${totalDiscount.toFixed(2)}`,
                            title: `Discount Savings`,
                        },
                        targetType: 'FIXED_AMOUNT',
                    },
                ];
                const res = await calculateShopifyOrder(
                    addressValues,
                    customer,
                    lineItems,
                    shipping,
                    trueDiscountApplications
                );
                const { userErrors, calculatedDraftOrder } = res.data.data.draftOrderCalculate;
                console.log('DRAFTORDERCALCULATE RES: ', calculatedDraftOrder.totalTax);
                if (userErrors.length) {
                    setPaymentErrorMessage(userErrors[0].message);
                }
                setTaxes(Number(calculatedDraftOrder.totalTax));
            })();
    }, [addressValues, customer, lineItems, shipping, trueLineItemsSubtotal]);
    // 01. CREATE SHOPIFY CUSTOMER
    const createShopifyCustomer = async (
        id,
        firstName,
        lastName,
        phone,
        acceptsMarketing,
        hasAccount
    ) => {
        try {
            const customer = await createCustomer(
                id,
                firstName,
                lastName,
                phone,
                acceptsMarketing,
                hasAccount
            );
            return customer;
        } catch (err) {
            console.log(err);
        }
    };
    // 02. ACTUALLY CHARGES THE CARD IN STRIPE:
    const setUpStripeSubscription = async (values, is_subscription) => {
        console.log('subscription payments');
        const setupIntent = await getSetupIntent();
        const { client_secret } = setupIntent.data;
        // can read stripe docs for more but basically a setupintent (this method) sets up card
        // for future purchases, i.e. post-purchase upsells / subscriptions
        const result = await stripe.confirmCardSetup(`${client_secret}`, {
            payment_method: {
                card: elements.getElement('cardNumber'),
                billing_details: {
                    name: cardName,
                    address: !hasOnlyGCs
                        ? {
                              city: values.city,
                              country: values.countryCodeV2,
                              line1: values.address1,
                              line2: values.address2 ? values.address2 : '',
                              postal_code: values.zip,
                              state: values.provinceCode,
                          }
                        : undefined,
                },
            },
        });
        if (result.error) {
            // Display error.message in your UI.
            return result.error;
        }
        // delivery one day after ship by
        const deliveryDate = new Date(shipByDate);
        deliveryDate.setDate(deliveryDate.getDate() + 1);
        let shipByStr, deliveryStr;
        console.log('VALS', values);
        if (hasOnlyGCs) {
            // gift card orders get the delivery date from a line item property decided on the pdp, as opposed to the date picker at checkout.
            const giftCardAttrs = lineItems.find(
                item => item.title === 'E-Gift Card'
            ).customAttributes;
            const gcEmail = giftCardAttrs.find(item => item.key === 'sender_email').value;
            values = {
                hasOnlyGCs: true,
                email: gcEmail,
            };
            deliveryStr = giftCardAttrs.find(item => item.key === 'delivery_date').value;
        } else {
            deliveryStr = `${
                deliveryDate.getMonth() + 1
            }-${deliveryDate.getDate()}-${deliveryDate.getFullYear()}`;
        }
        console.log('VALS', values);
        const subRes = await createStripeCustomer(
            result.setupIntent,
            values,
            subscriptionItems,
            customer ? customer.id : '',
            is_subscription,
            deliveryStr,
            hasOnlyGCs
        );
        if (subRes.error) {
            console.log('error: ', subRes.error);
            throw new Error(subRes.error);
        } else {
            console.log('sub payment setup success');
        }
        return subRes;
        // The setup has succeeded. Display a success message.
    };

    // 02. ACTUALLY CHARGES THE CARD IN STRIPE:
    const submitPayment = async (draftOrder, values, totalPrice, subRes) => {
        // first, charge the card for the initial order:
        console.log('initial order');
        const truePrice = Number(totalPrice.toFixed(2));
        console.log('TRUE PRICE: ', truePrice);
        const paymentIntent = await getPaymentIntent(
            draftOrder.id,
            truePrice,
            subRes.data.charge_id,
            subRes.data.stripe_id
        );
        console.log('PI: ', paymentIntent);
        const { client_secret } = paymentIntent.data;
        return stripe.confirmCardPayment(`${client_secret}`, {
            payment_method: {
                card: elements.getElement('cardNumber'),
                billing_details: {
                    name: cardName,
                    address: !hasOnlyGCs
                        ? {
                              city: values.city,
                              country: values.countryCodeV2,
                              line1: values.address1,
                              line2: values.address2 ? values.address2 : '',
                              postal_code: values.zip,
                              state: values.provinceCode,
                          }
                        : undefined,
                },
            },
        });
    };
    // 03. CREATE SHOPIFY ORDER
    const createShopifyOrderFn = async (values, billingValues, customerObj) => {
        let totalDiscount =
            trueLineItemsBasePriceSubtotalNoDiscountsSubscriptionsIncluded - trueLineItemsSubtotal;
        // combining all discounts into one discount (you can only have one applied discount per order in shopify)
        const trueDiscountApplications = [
            {
                value: {
                    amount: `${totalDiscount.toFixed(2)}`,
                    title: `Discount Savings`,
                },
                targetType: 'FIXED_AMOUNT',
            },
        ];
        const deliveryDate = new Date(shipByDate);
        deliveryDate.setDate(deliveryDate.getDate() + 1);
        let shipByStr, deliveryStr;
        if (hasOnlyGCs) {
            // gift
            const giftCardAttrs = lineItems.find(
                item => item.title === 'E-Gift Card'
            ).customAttributes;
            const gcEmail = giftCardAttrs.find(item => item.key === 'sender_email').value;
            values = {
                hasOnlyGCs: true,
                email: gcEmail,
            };
            shipByStr = '';
            deliveryStr = '';
        } else {
            shipByStr = `${
                shipByDate.getMonth() + 1
            }-${shipByDate.getDate()}-${shipByDate.getFullYear()}`;
            deliveryStr = `${
                deliveryDate.getMonth() + 1
            }-${deliveryDate.getDate()}-${deliveryDate.getFullYear()}`;
        }
        const res = await createShopifyOrder(
            values,
            customerObj,
            lineItems,
            shipping,
            trueDiscountApplications,
            shipByStr,
            deliveryStr,
            appliedGiftCards,
            localStorage.getItem('COUSINS_MAINE_GN'),
            billingValues,
            giftCardPrices.giftCardSum,
            totalPrice
        );
        console.log('DRAFTORDERCREATE RES: ', res);
        return res.data.data.draftOrderCreate.draftOrder;
    };
    // 04. REDEEM ANY GIFTCARDS
    const redeemGiftcardsFn = async (order_id, order_name) => {
        const res = await redeemGiftcards(appliedGiftCards, giftCardPrices, order_id, order_name);
        console.log('REDEEMGC RES: ', res);
        return res.data;
    };

    // 05. COMPLETE SHOPIFY ORDER
    const completeShopifyOrderFn = async (order_id, charge_id, stripe_id) => {
        const completedShopifyOrder = await completeShopifyOrder(order_id);
        console.log('success!!!', completedShopifyOrder);
        const { id, name } = completedShopifyOrder.data.data.draftOrderComplete.draftOrder.order;
        const completedOrderId = id.replace('gid://shopify/Order/', '');
        console.log('completedOrderId', completedOrderId);
        await redeemGiftcardsFn(completedOrderId, name);
        const addOrderIdsToCustomerResp = addOrderIdsToCustomer(charge_id, completedOrderId);
        console.log('ADDY VALS: ', addressValues);
        const mailingAddressInputs = {
            address1: addressValues.address1,
            address2: addressValues.address2,
            city: addressValues.city,
            country: addressValues.countryCodeV2,
            firstName: addressValues.firstName,
            lastName: addressValues.lastName,
            province: addressValues.provinceCode,
            zip: addressValues.zip,
            phone: addressValues.phone,
        };
        updateShippingAddress(
            client,
            localStorage.getItem('shopify_checkout_id'),
            mailingAddressInputs
        );
        localStorage.removeItem('COUSINS_MAINE_GN');
        let shipByStr;
        if (hasOnlyGCs) {
            const giftCardAttrs = lineItems.find(
                item => item.title === 'E-Gift Card'
            ).customAttributes;
            let temp = giftCardAttrs.find(item => item.key === 'delivery_date').value;
            if (temp) {
                let tempDate = new Date(temp);
                tempDate.setDate(tempDate.getDate() - 1);
                shipByStr = `${tempDate.getFullYear()}-${
                    tempDate.getMonth() + 1
                }-${tempDate.getDate()}`;
            } else if (shipByDate) {
                shipByStr = `${shipByDate.getFullYear()}-${
                    shipByDate.getMonth() + 1
                }-${shipByDate.getDate()}`;
            }
        } else {
            shipByStr = `${shipByDate.getFullYear()}-${
                shipByDate.getMonth() + 1
            }-${shipByDate.getDate()}`;
        }
        try {
            if (discountApplications.length) {
                console.log(
                    discountApplications[0].code,
                    totalPrice,
                    trueLineItemsSubtotalNoDiscounts - trueLineItemsSubtotal
                );
                sendDiscountCodeData(
                    discountApplications[0].code,
                    totalPrice,
                    trueLineItemsSubtotalNoDiscounts - trueLineItemsSubtotal
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            window.location.href = `/order-completed?a=${uuidv4()}&oid=${completedOrderId}&sid=${stripe_id}&u=${
                addressValues.email
            }&od=${shipByStr}&t=${taxes}&s=${shipping}${
                giftCardPrices.giftCardSum ? `&gc_sum=${giftCardPrices.giftCardSum}` : ''
            }${
                giftCardPrices.gcTotal ? `&gc_total=${giftCardPrices.gcTotal}` : ''
            }&status=complete&only_gcs=${hasOnlyGCs}&z=${uuidv4()}`;
        }
    };

    // RUNS ALL OF THE ABOVE FUNCTIONS
    const submitFn = async (billingValues, diffBilling, setSubmitting) => {
        if (!hasOnlyGCs) {
            // deriving billing values and adding necessary data that isn't provided by the billing form
            billingValues = diffBilling ? billingValues : addressValues;
            billingValues.email = addressValues.email;
            addressValues.phone =
                '+1' +
                addressValues.phone
                    .trim()
                    .replace('+1', '')
                    .replace('(', '')
                    .replace(')', '')
                    .replace('-', '')
                    .replace('-', '');
            billingValues.phone = addressValues.phone;
        }
        try {
            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }
            const createdOrder = await createShopifyOrderFn(addressValues, billingValues);
            console.log('CREATED ORDER: ', createdOrder);
            let createdCustomer;
            if (!hasOnlyGCs) {
                createdCustomer = await createShopifyCustomer(
                    createdOrder.customer.id,
                    billingValues.firstName,
                    billingValues.lastName,
                    billingValues.phone,
                    billingValues.acceptsMarketing,
                    !!customer
                );
                console.log('CREATED CUSTOMER: ', createdCustomer);
            } else {
                // gift card orders use line item attrs to create customer
                const giftCardAttrs = lineItems.find(
                    item => item.title === 'E-Gift Card'
                ).customAttributes;
                let senderName = giftCardAttrs.find(item => item.key === 'sender_name').value;
                let firstName, lastName;
                if (createdOrder.customer.firstName) {
                    firstName = createdOrder.customer.firstName;
                } else {
                    firstName = senderName.split(' ')[0];
                }
                if (createdOrder.customer.lastName) {
                    lastName = createdOrder.customer.lastName;
                } else {
                    const splitLastName = senderName.split(' ');
                    lastName = splitLastName.slice(1, splitLastName.length).join(' ');
                }
                createdCustomer = await createShopifyCustomer(
                    createdOrder.customer.id,
                    firstName,
                    lastName,
                    createdOrder.customer.phone,
                    createdOrder.customer.acceptsMarketing,
                    !!customer
                );
                console.log('CREATED CUSTOMER: ', createdCustomer);
            }
            const paymentValues = diffBilling ? billingValues : addressValues;
            if (totalPrice) {
                const subRes = await setUpStripeSubscription(
                    // using the addressValues for this as we store where to ship subscription in the future
                    addressValues,
                    !!subscriptionItems.length
                );
                console.log('subRes: ', subRes);
                if (!subRes.data) {
                    throw new Error(
                        subRes.message
                            ? subRes.message
                            : 'An error has occurred. Try again and contact us if you continue to see this.'
                    );
                }
                const payment = await submitPayment(
                    createdOrder,
                    paymentValues,
                    totalPrice,
                    subRes
                );
                console.log(payment);
                if (payment && payment.error) {
                    console.log('error: ', payment.error);
                    throw new Error(payment.error.message);
                    // Display error.message in your UI.
                } else {
                    console.log('yay');
                    console.log('subscriptionItems.length', subscriptionItems.length);
                    completeShopifyOrderFn(
                        createdOrder.id,
                        subRes.data.charge_id,
                        subRes.data.stripe_id
                    );
                }
            } else {
                completeShopifyOrderFn(createdOrder.id);
            }
        } catch (err) {
            console.log(err);
            setSubmitting(false);
            setPaymentErrorMessage(err.message);
        }
    };
    // test cards:
    // 4242424242424242  success
    // 4000002500003155  auth
    // 4000000000009995  fail
    return (
        <FlexBox direction="column" className="checkout-form">
            <BackgroundDiv
                className="background-map"
                left="5%"
                top="-5%"
                topMobile="20%"
                z="-1"
                css={css`
                    width: 747px;
                `}
            >
                <Image fluid={getImage(bgImages.edges, 'blurred_smudge')} alt="" />
            </BackgroundDiv>
            <nav className={`checkout-form-header active-page-${activePage}`} aria-label="breadcrumbs">
                <button
                    onClick={() => setActivePage(1)}
                    className={`header-cont ${activePage === 1 ? 'active' : ''} ${
                        activePage >= 1 ? 'prev' : ''
                    }`}
                    aria-current={activePage === 1 ? 'true' : 'false'}
                >
                    <CustInfoIcon />
                    <Subheading as="span">Information</Subheading>
                </button>
                <DotTrailIcon className={`dot-trail ${activePage > 1 ? 'active' : ''}`} />
                <button
                    onClick={() => {
                        if (activePage >= 2) {
                            setActivePage(2);
                        }
                    }}
                    className={`header-cont ${activePage === 2 ? 'active' : ''} ${
                        activePage >= 2 ? 'prev' : ''
                    }`}
                    aria-current={activePage === 2 ? 'true' : 'false'}
                >
                    <ShippingIcon />
                    <Subheading as="span">Shipping</Subheading>
                </button>
                <DotTrailIcon className={`dot-trail ${activePage > 2 ? 'active' : ''}`} />
                <button
                    onClick={() => {
                        if (activePage >= 3) {
                            setActivePage(3);
                        }
                    }}
                    className={`header-cont ${activePage === 3 ? 'active' : ''} ${
                        activePage >= 3 ? 'prev' : ''
                    }`}
                    aria-current={activePage === 3 ? 'true' : 'false'}
                >
                    <PaymentIcon />
                    <Subheading as="span">Payment</Subheading>
                </button>
            </nav>
            {activePage === 1 ? (
                <AddressForm
                    customer={customer}
                    setCustomer={setCustomer}
                    addressValues={addressValues}
                    setAddressValues={setAddressValues}
                    setTaxes={setTaxes}
                    setActivePage={setActivePage}
                    registrationStatus={registrationStatus}
                    setPaymentErrorMessage={setPaymentErrorMessage}
                    totalPrice={totalPrice}
                />
            ) : null}
            {activePage === 2 ? (
                <ShippingSection
                    addressValues={addressValues}
                    setActivePage={setActivePage}
                    shipping={shipping}
                    shipByDate={shipByDate}
                    setShipByDate={setShipByDate}
                    registrationStatus={registrationStatus}
                    isApparelOnly={isApparelOnly}
                />
            ) : null}
            {activePage === 3 ? (
                <CardSection
                    cardName={cardName}
                    setCardName={setCardName}
                    submitFn={submitFn}
                    setActivePage={setActivePage}
                    hasOnlyGCs={hasOnlyGCs}
                />
            ) : null}
        </FlexBox>
    );
};

export default CheckoutForm;
