import React, { useState } from 'react';
import Cookies from 'js-cookie';
import Modal from '~/components/.base/modal';
import { HeadingLarge } from '~/components/.base/headings';
import { Grid } from '~/components/.base/containers';
import CheckoutLogin from './CheckoutLogin';
import CheckoutRegister from './CheckoutRegister';
import {
    createShopifyCustomer,
    authenticateShopifyCustomer,
} from '~/helpers/requests/customer-requests';
import './AccountModal.scss';

const AccountModal = ({
    modalIsOpen,
    setModalIsOpen,
    accountModalType,
    setAccountModalType,
    setCustomer,
}) => {
    const [registerError, setRegisterError] = useState(null);
    const registerFn = async (values, { setSubmitting }) => {
        const createdCustomer = await createShopifyCustomer(
            values.firstName,
            values.lastName,
            values.email,
            values.password
        );
        if (createdCustomer) {
            console.log('CUST RES: ', createdCustomer);
            if (
                createdCustomer.data.data.customerCreate.userErrors &&
                createdCustomer.data.data.customerCreate.userErrors.length
            ) {
                setRegisterError(createdCustomer.data.data.customerCreate.userErrors[0].message);
            } else {
                setCustomer(createdCustomer.data.data.customerCreate.customer);
                authenticateShopifyCustomer(values.email, values.password).then(res => {
                    // set cookie with access token / exp.date
                    console.log('CUST AUTH RES: ', res);
                    const { customerAccessTokenCreate } = res.data.data;
                    if (!customerAccessTokenCreate.userErrors.length) {
                        const { accessToken, expiresAt } =
                            customerAccessTokenCreate.customerAccessToken;
                        const expiryDate = new Date(expiresAt);
                        Cookies.set('KEPT_SESS', accessToken, {
                            expires: expiryDate,
                        });
                        window.location.href = '/checkout';
                    }
                    // todo: add the address to the customer acct.
                });
            }
        }
    };
    const logInFn = async (values, { setSubmitting }) => {
        authenticateShopifyCustomer(values.email, values.password).then(res => {
            // set cookie with access token / exp.date
            console.log('CUST AUTH RES: ', res);
            const { customerAccessTokenCreate } = res.data.data;
            if (!customerAccessTokenCreate.userErrors.length) {
                const { accessToken, expiresAt } = customerAccessTokenCreate.customerAccessToken;
                const expiryDate = new Date(expiresAt);
                Cookies.set('KEPT_SESS', accessToken, {
                    expires: expiryDate,
                });
                window.location.href = '/checkout';
            }
        });
    };
    return (
        <Modal
            open={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
            ariaLabelledby="account-modal-title"
            top="0"
            modalMaxWidth="700px"
        >
            <div id="account-modal-title" className="account-modal-title">
                <HeadingLarge>
                    {accountModalType === 'login' ? (
                        <span>
                            Log <span className="red">In</span>
                        </span>
                    ) : (
                        <span>
                            Create An <span className="red">Account</span>
                        </span>
                    )}
                </HeadingLarge>
            </div>
            <Grid className="account-modal-body">
                {accountModalType === 'login' ? (
                    <CheckoutLogin setAccountModalType={setAccountModalType} logInFn={logInFn} />
                ) : null}
                {accountModalType === 'register' ? (
                    <CheckoutRegister
                        setAccountModalType={setAccountModalType}
                        registerFn={registerFn}
                        registerError={registerError}
                    />
                ) : null}
            </Grid>
        </Modal>
    );
};

export default AccountModal;
