import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import { CheckoutInput, CheckoutSelect, CheckboxInput } from '~/components/.base/inputs';
import { UnderscoreLink } from '~/components/.base/links';
import { Field } from 'formik';
import { Grid, Row, Col, FlexBox } from '~/components/.base/containers';
import { Heading, BodyText } from '~/components/.base/headings';
import ArrowDown from '~/images/icons/arrow_normal_down.svg';
// If you want to use the provided css
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import { css } from 'styled-components';

const AddressFormInputs = ({
    addressValues,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    registrationStatus,
    setFieldValue,
    setModalIsOpen,
    setAccountModalType,
    hideLogin,
    toggleSubmit,
}) => {
    const stateLists = {
        US: {
            AK: 'Alaska',
            AL: 'Alabama',
            AR: 'Arkansas',
            AZ: 'Arizona',
            CA: 'California',
            CO: 'Colorado',
            CT: 'Connecticut',
            DC: 'District of Columbia',
            DE: 'Delaware',
            FL: 'Florida',
            GA: 'Georgia',
            HI: 'Hawaii',
            IA: 'Iowa',
            ID: 'Idaho',
            IL: 'Illinois',
            IN: 'Indiana',
            KS: 'Kansas',
            KY: 'Kentucky',
            LA: 'Louisiana',
            MA: 'Massachusetts',
            MD: 'Maryland',
            ME: 'Maine',
            MI: 'Michigan',
            MN: 'Minnesota',
            MO: 'Missouri',
            MS: 'Mississippi',
            MT: 'Montana',
            NC: 'North Carolina',
            ND: 'North Dakota',
            NE: 'Nebraska',
            NH: 'New Hampshire',
            NJ: 'New Jersey',
            NM: 'New Mexico',
            NV: 'Nevada',
            NY: 'New York',
            OH: 'Ohio',
            OK: 'Oklahoma',
            OR: 'Oregon',
            PA: 'Pennsylvania',
            PR: 'Puerto Rico',
            RI: 'Rhode Island',
            SC: 'South Carolina',
            SD: 'South Dakota',
            TN: 'Tennessee',
            TX: 'Texas',
            UT: 'Utah',
            VA: 'Virginia',
            VT: 'Vermont',
            WA: 'Washington',
            WI: 'Wisconsin',
            WV: 'West Virginia',
            WY: 'Wyoming',
        },
        CA: {
            AB: 'Alberta',
            BC: 'British Columbia',
            MB: 'Manitoba',
            NB: 'New Brunswick',
            NL: 'Newfoundland',
            NS: 'Nova Scotia',
            NT: 'Northwest Territories',
            NU: 'Nunavut',
            ON: 'Ontario',
            PE: 'Prince Edward Island',
            QC: 'Quebec',
            SK: 'Saskatchewan',
            YT: 'Yukon',
        },
    };
    const [provinceCode, setProvinceCode] = useState('AK');
    const [countryCodeV2, setCountryCodeV2] = useState('US');
    const [address1, setAddress1] = useState('');

    const emailFieldRef = useRef(null);
    const phoneFieldRef = useRef(null);
    const acceptsMarketingFieldRef = useRef(null);
    const firstNameFieldRef = useRef(null);
    const lastNameFieldRef = useRef(null);
    const address1FieldRef = useRef(null);
    const address2FieldRef = useRef(null);
    const cityFieldRef = useRef(null);
    const provinceCodeFieldRef = useRef(null);
    const zipFieldRef = useRef(null);

    useEffect(() => {
        console.log(errors, toggleSubmit);
        if (errors.zip && zipFieldRef?.current) zipFieldRef.current.focus();

        if (errors.provinceCode && provinceCodeFieldRef?.current)
            provinceCodeFieldRef.current.focus();

        if (errors.city && cityFieldRef?.current) cityFieldRef.current.focus();

        if (errors.address2 && address2FieldRef?.current) address2FieldRef.current.focus();

        if (errors.address1 && address1FieldRef?.current) address1FieldRef.current.focus();

        if (errors.lastName && lastNameFieldRef?.current) lastNameFieldRef.current.focus();

        if (errors.firstName && firstNameFieldRef?.current) firstNameFieldRef.current.focus();

        if (errors.acceptsMarketing && acceptsMarketingFieldRef?.current)
            acceptsMarketingFieldRef.current.focus();

        if (errors.phone && phoneFieldRef?.current) phoneFieldRef.current.focus();

        if ((errors.email || errors.account) && emailFieldRef?.current)
            emailFieldRef.current.focus();
    }, [toggleSubmit, errors]);

    const syncStateToFormik = () => {
        values.provinceCode = provinceCode;
        values.countryCodeV2 = countryCodeV2;
        values.address1 = address1;
    };

    // these functions I think are because some of the values weren't mapping to formik correctly so I had to store them in state.
    // I wouldn't mess with it unless something is really wrong.

    const syncAddressValuesToState = () => {
        if (addressValues.provinceCode && addressValues.provinceCode !== provinceCode) {
            setProvinceCode(addressValues.provinceCode);
        }
        if (addressValues.countryCodeV2 && addressValues.countryCodeV2 !== countryCodeV2) {
            setCountryCodeV2(addressValues.countryCodeV2);
        }
        if (addressValues.address1 && addressValues.address1 !== address1) {
            setAddress1(addressValues.address1);
        }
    };
    useEffect(syncStateToFormik, [values, provinceCode, countryCodeV2, address1]);
    useEffect(syncAddressValuesToState, [addressValues]);

    const handleChangeAddress1 = a => {
        setAddress1(a);
    };

    const handleSelect = address => {
        geocodeByAddress(address).then(results => {
            const { address_components, formatted_address } = results[0];
            setAddress1(formatted_address.split(', ')[0]);
            setFieldValue(
                'city',
                address_components.find(c => c.types.includes('locality')).long_name
            );
            setFieldValue(
                'zip',
                address_components.find(c => c.types.includes('postal_code')).long_name
            );
            setProvinceCode(
                address_components.find(c => c.types.includes('administrative_area_level_1'))
                    .short_name
            );
            setCountryCodeV2(address_components.find(c => c.types.includes('country')).short_name);
        });
    };

    const handleLogOut = () => {
        Cookies.remove('KEPT_SESS');
        window.location.href = '/checkout';
    };

    return (
        <section>
            <div className="checkout-form-section">
                <Grid>
                    <FlexBox justify="space-between" className="header-row">
                        <Heading>Contact information</Heading>
                        {!hideLogin ? (
                            <BodyText className="aux-message">
                                <UnderscoreLink
                                    as="button"
                                    type="button"
                                    className="link"
                                    onClick={() => {
                                        setModalIsOpen(true);
                                        setAccountModalType('login');
                                    }}
                                >
                                    Log in
                                </UnderscoreLink>{' '}
                                or{' '}
                                <UnderscoreLink
                                    as="button"
                                    type="button"
                                    className="link"
                                    onClick={() => {
                                        setModalIsOpen(true);
                                        setAccountModalType('register');
                                    }}
                                >
                                    Sign up
                                </UnderscoreLink>
                            </BodyText>
                        ) : (
                            <BodyText className="aux-message">
                                <button className="link" onClick={handleLogOut}>
                                    Log out
                                </button>
                            </BodyText>
                        )}
                    </FlexBox>
                    <Row className="form-row">
                        <Col size={1}>
                            <CheckoutInput>
                                <label htmlFor="email">Email</label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    className={`field full${
                                        !!errors.email || !!errors.account ? ' is-invalid' : ''
                                    }`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    ref={emailFieldRef}
                                    autoComplete="email"
                                    aria-describedby="emailError"
                                />
                                <div id="emailError" className="error">
                                    {errors.email ? errors.email : errors.account}
                                </div>
                            </CheckoutInput>
                        </Col>
                        <Col size={1}>
                            <CheckoutInput>
                                <label htmlFor="phone">Phone</label>
                                <input
                                    id="phone"
                                    name="phone"
                                    type="text"
                                    className={`field full${!!errors.phone ? ' is-invalid' : ''}`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.phone}
                                    ref={phoneFieldRef}
                                    autoComplete="tel"
                                    aria-describedby="phoneError"
                                />
                                <div id="phoneError" className="error">
                                    {errors.phone}
                                </div>
                            </CheckoutInput>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col size={1}>
                            <br />
                            <CheckboxInput
                                css={css`
                                    display: inline-flex;
                                    .checkmarkIcon {
                                        display: block;
                                        margin-right: 0.5rem;
                                        border: 1px solid #5c6566;
                                        border-radius: 50%;
                                        cursor: pointer;
                                        width: 16px;
                                        height: 16px;
                                        background-size: 100%;
                                    }
                                    #acceptsMarketing {
                                        width: 100%;
                                        height: 100%;
                                        opacity: 1;
                                        appearance: none;
                                        outline: none;
                                    }
                                    #acceptsMarketing:checked + .checkmarkIcon {
                                        background-image: url('/images/icons/checkbox.svg');
                                        border-color: #d4212c;
                                    }
                                    #acceptsMarketing:focus {
                                        box-shadow: 0 0 0 1px rgba(212, 33, 44, 0.75),
                                            0 0 0 3px rgba(255, 255, 255, 0.75);
                                    }
                                `}
                            >
                                <input
                                    type="checkbox"
                                    name="acceptsMarketing"
                                    id="acceptsMarketing"
                                    ref={acceptsMarketingFieldRef}
                                    aria-describedby="acceptsMarketingError"
                                    value="true"
                                />
                                <span className="checkmarkIcon" />
                                <span>Keep me up to date on news and exclusive offers</span>
                            </CheckboxInput>
                            <div id="acceptsMarketingError" className="error">
                                {errors.acceptsMarketing}
                            </div>
                        </Col>
                    </Row>
                    <FlexBox justify="space-between" className="header-row">
                        <Heading>Shipping Address</Heading>
                    </FlexBox>
                    <Row className="form-row">
                        <Col size={1}>
                            <CheckoutInput>
                                <label htmlFor="firstName">First Name</label>
                                <input
                                    id="firstName"
                                    name="firstName"
                                    className={`field${!!errors.firstName ? ' is-invalid' : ''}`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.firstName}
                                    ref={firstNameFieldRef}
                                    aria-describedby="firstNameError"
                                    autoComplete="given-name"
                                />
                                <div id="firstNameError" className="error">
                                    {errors.firstName}
                                </div>
                            </CheckoutInput>
                        </Col>
                        <Col size={1}>
                            <CheckoutInput>
                                <label htmlFor="lastName">Last Name</label>
                                <input
                                    id="lastName"
                                    name="lastName"
                                    className={`field${!!errors.lastName ? ' is-invalid' : ''}`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.lastName}
                                    ref={lastNameFieldRef}
                                    aria-describedby="lastNameError"
                                    autoComplete="family-name"
                                />
                                <div id="lastNameError" className="error">
                                    {errors.lastName}
                                </div>
                            </CheckoutInput>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col size={1}>
                            <CheckoutInput>
                                <label htmlFor="address1">Address 1</label>
                                <PlacesAutocomplete
                                    value={address1}
                                    onChange={handleChangeAddress1}
                                    onSelect={handleSelect}
                                >
                                    {({
                                        getInputProps,
                                        suggestions,
                                        getSuggestionItemProps,
                                        loading,
                                    }) => {
                                        return (
                                            <CheckoutInput>
                                                <input
                                                    id="address1"
                                                    name="address1"
                                                    {...getInputProps({
                                                        placeholder: 'Search Places ...',
                                                        className: `location-search-input${
                                                            !!errors.address1 ? ' is-invalid' : ''
                                                        }`,
                                                    })}
                                                    ref={address1FieldRef}
                                                    autoComplete="address-line1"
                                                    aria-describedby="address1Error"
                                                />
                                                <div className="autocomplete-dropdown-container">
                                                    {suggestions.map((suggestion, i) => {
                                                        const className = suggestion.active
                                                            ? 'suggestion-item--active'
                                                            : 'suggestion-item';
                                                        // inline style for demonstration purpose
                                                        const style = suggestion.active
                                                            ? {
                                                                  backgroundColor: '#fafafa',
                                                                  cursor: 'pointer',
                                                              }
                                                            : {
                                                                  backgroundColor: '#ffffff',
                                                                  cursor: 'pointer',
                                                              };
                                                        return (
                                                            <div
                                                                key={i}
                                                                {...getSuggestionItemProps(
                                                                    suggestion,
                                                                    {
                                                                        className,
                                                                        style,
                                                                    }
                                                                )}
                                                            >
                                                                <span>
                                                                    {suggestion.description}
                                                                </span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                <div id="address1Error" className="error">
                                                    {errors.address1}
                                                </div>
                                            </CheckoutInput>
                                        );
                                    }}
                                </PlacesAutocomplete>
                            </CheckoutInput>
                        </Col>
                        <Col size={1}>
                            <CheckoutInput>
                                <label htmlFor="address2">Address 2</label>
                                <input
                                    id="address2"
                                    name="address2"
                                    className={`field${!!errors.address2 ? ' is-invalid' : ''}`}
                                    placeholder="Enter your address"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.address2}
                                    ref={address2FieldRef}
                                    autoComplete="address-line2"
                                    aria-describedby="address2Error"
                                />
                                <div id="address2Error" className="error">
                                    {errors.address2}
                                </div>
                            </CheckoutInput>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col size={1}>
                            <CheckoutInput>
                                <label htmlFor="city">City</label>
                                <input
                                    id="city"
                                    name="city"
                                    className={`field${!!errors.city ? ' is-invalid' : ''}`}
                                    placeholder="Enter your city"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.city}
                                    ref={cityFieldRef}
                                    aria-describedby="cityError"
                                    autoComplete="address-level2"
                                />
                                <div id="cityError" className="error">
                                    {errors.city}
                                </div>
                            </CheckoutInput>
                        </Col>
                        <Col size={1}>
                            <CheckoutSelect
                                id="state"
                                className={!!errors.provinceCode ? ' is-invalid' : ''}
                            >
                                <label htmlFor="provinceCode">State</label>
                                <select
                                    id="provinceCode"
                                    name="provinceCode"
                                    placeholder="State"
                                    onChange={e => {
                                        console.log(provinceCode);
                                        setProvinceCode(e.target.value);
                                    }}
                                    onBlur={e => {
                                        console.log(provinceCode);
                                        setProvinceCode(e.target.value);
                                    }}
                                    value={provinceCode}
                                    disabled={
                                        !values.countryCodeV2 === 'US' &&
                                        !values.countryCodeV2 === 'CA'
                                    }
                                    ref={provinceCodeFieldRef}
                                    aria-describedby="provinceCodeError"
                                    autoComplete="address-level1"
                                >
                                    {values.countryCodeV2 === 'US' ||
                                    values.countryCodeV2 === 'CA' ? (
                                        Object.entries(stateLists[values.countryCodeV2]).map(
                                            (state, i) => (
                                                <option key={i} value={state[0]}>
                                                    {state[1]}
                                                </option>
                                            )
                                        )
                                    ) : (
                                        <option value="">State</option>
                                    )}
                                </select>
                                <ArrowDown style={{ pointerEvents: 'none' }} />
                                <div id="provinceCodeError" className="error">
                                    {errors.provinceCode}
                                </div>
                            </CheckoutSelect>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col size={1}>
                            <CheckoutInput>
                                <label htmlFor="zip">Zip Code</label>
                                <input
                                    id="zip"
                                    name="zip"
                                    className={`field${!!errors.zip ? ' is-invalid' : ''}`}
                                    placeholder="Enter your ZIP Code"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.zip}
                                    ref={zipFieldRef}
                                    aria-describedby="zipError"
                                    autoComplete="postal-code"
                                />
                                <div id="zipError" className="error">
                                    {errors.zip}
                                </div>
                            </CheckoutInput>
                        </Col>
                        <Col size={1}>
                            <CheckoutSelect
                                id="country"
                                className={!!errors.countryCodeV2 ? ' is-invalid' : ''}
                            >
                                <label htmlFor="countryCodeV2">Country</label>
                                <select
                                    id="countryCodeV2"
                                    name="countryCodeV2"
                                    placeholder="Country"
                                    onBlur={e => setCountryCodeV2(e.target.value)}
                                    onChange={e => setCountryCodeV2(e.target.value)}
                                    value={countryCodeV2}
                                    autoComplete="country-name"
                                >
                                    <option value="US">United States</option>
                                </select>
                                <ArrowDown style={{ pointerEvents: 'none' }} />
                            </CheckoutSelect>
                        </Col>
                    </Row>
                </Grid>
            </div>
        </section>
    );
};

export default AddressFormInputs;
