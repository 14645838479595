import React, { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import SiteContext from '~/layouts/StoreContext';
import Image from 'gatsby-image';

import { Heading, BodyTextAlt } from '~/components/.base/headings';
import { CheckoutInput } from '~/components/.base/inputs';
import { WhiteButton } from '~/components/.base/buttons';
import { FlexBox } from '~/components/.base/containers';
import LineItem from './LineItem';
import './CheckoutCart.scss';
import { applyGiftCard } from '~/helpers/requests/checkout-requests';
import styled, { css } from 'styled-components';
import { breakpoint } from '../../../constants/Breakpoint';
import { Secure } from '../../.base/Icons';
import { getImage } from '../../../helpers';
import TagIcon from '~/images/icons/tag.svg';

const Payments = styled.div`
  margin-top: 15px;
  .gatsby-image-wrapper {
    width: 180px;
    max-width: 100%;
  }
`;

const SecureCheckout = styled(FlexBox)`
  margin-top: 15px;
`;

const Footer = styled(FlexBox)`
  margin-top: auto;
  padding: 20px 0;
  flex-wrap: wrap;
  .icon {
    color: #d4212c;
  }
  .icon--secure {
    width: 32px;
    margin-right: 10px;
  }
  @media ${breakpoint.lg} {
    justify-content: space-between;
  }
  @media ${breakpoint.maxMd} {
    flex-direction: column;
  }
  @media ${breakpoint.maxSm} {
    align-items: center;
  }
`;

const CheckoutCart = ({
  totalPrice,
  taxes,
  shipping,
  setFreeShipping,
  appliedGiftCards,
  setAppliedGiftCards,
  giftCardPrices,
  checkoutImages,
  hasApparel,
  isApparelOnly,
  hasOnlyGCs,
}) => {
  const {
    store: {
      client,
      checkout: { lineItems, discountApplications },
      trueLinePrices,
      trueLineItemsSubtotal,
      trueLineItemsSubtotalNoDiscounts,
      trueLineItemsBasePriceSubtotalNoDiscounts,
    },
    applyDiscount,
    removeDiscount,
  } = useContext(SiteContext);
  const [discountCode, setDiscountCode] = useState('');
  useEffect(() => {
    if (discountApplications && discountApplications.length) {
      if (discountApplications[0].targetType === 'SHIPPING_LINE') {
        setFreeShipping(true);
      } else {
        setFreeShipping(false);
      }
      setDiscountCode(discountApplications[0].code);
    } else {
      setDiscountCode('');
      setFreeShipping(false);
    }
  }, [discountApplications, setFreeShipping]);
  const applyDiscountCode = async () => {
    if (totalPrice) {
      // checks if it's a giftcard first, as they use the same field
      const hasUsedDiscountCode = !!appliedGiftCards.find(giftCard => giftCard.code === discountCode);

      if (!hasUsedDiscountCode) {
        applyGiftCard(discountCode).then(res => {
          if (res.data.recognized) {
            setAppliedGiftCards(gcs =>
              gcs.concat({
                code: discountCode,
                amount: res.data.amount,
              })
            );
            setDiscountCode('');
          } else {
            applyDiscount(client, localStorage.getItem('shopify_checkout_id'), discountCode);
          }
        });
      } else {
        alert(`This discount code ${discountCode} has already been applied`);
      }
    } else {
      alert('Balance already 0');
    }
  };
  const removeDiscountCode = () => {
    removeDiscount(client, localStorage.getItem('shopify_checkout_id'));
  };
  const removeGiftcard = code => {
    setAppliedGiftCards(gcs => gcs.filter(gc => gc.code !== code));
  };
  const shippingSavings = useMemo(() => {
    return isApparelOnly || hasOnlyGCs ? 0 : hasApparel ? 35 - shipping + 9.99 : 35 - shipping;
  }, [isApparelOnly, hasApparel, shipping]);
  return (
    <FlexBox
      direction="column"
      css={css`
        height: 100%;
      `}
    >
      <Heading>
        <span className="red">Cart</span> Items
      </Heading>
      <ul className="cart-section line-items">
        {lineItems.map((item, i) => (
          <li key={i}>
            <LineItem line_item={item} trueLinePrices={trueLinePrices} index={i} />
          </li>
        ))}
      </ul>
      <div className="cart-section coupons">
        <FlexBox
          justify="space-between"
          align="flex-end"
          css={css`
            /* Fix for coupon placeholder getting cut off */
            @media ${breakpoint.maxXs} {
              flex-direction: column;
              ${CheckoutInput} {
                width: 100%;
                input {
                  min-width: 100%;
                }
              }
              ${WhiteButton} {
                margin-top: 10px;
              }
            }
            @media ${breakpoint.onlyLg} {
              flex-direction: column;
              ${CheckoutInput} {
                width: 100%;
                input {
                  min-width: 100%;
                }
              }
              ${WhiteButton} {
                margin-top: 10px;
              }
            }
          `}
        >
          <CheckoutInput w="calc(100% - 10px)" md="0px">
            <div className="label">Coupon / Gift Card</div>
            <input
              name="coupon"
              type="text"
              placeholder="Discount code or gift card"
              onChange={e => setDiscountCode(e.target.value)}
              value={discountCode.toUpperCase()}
            />
          </CheckoutInput>
          <WhiteButton pd="0 35px" onClick={applyDiscountCode}>
            Apply
          </WhiteButton>
        </FlexBox>
        {discountApplications && discountApplications.length
          ? discountApplications.map((disc, i) => (
              <div key={i} className="applied-code">
                {disc.code.toUpperCase()}
                <button onClick={removeDiscountCode}>&times;</button>
              </div>
            ))
          : null}
        {appliedGiftCards?.length
          ? appliedGiftCards.map((gc, i) => (
              <div key={i} className="applied-code">
                {gc.code.toUpperCase()}
                <button onClick={() => removeGiftcard(gc.code)}>&times;</button>
              </div>
            ))
          : null}
      </div>
      <div className="cart-section totals">
        <div className="total-item">
          <BodyTextAlt>Items</BodyTextAlt>
          <BodyTextAlt>
            $
            {(trueLineItemsSubtotal || trueLineItemsSubtotal === 0) &&
            trueLineItemsBasePriceSubtotalNoDiscounts
              ? `${trueLineItemsBasePriceSubtotalNoDiscounts.toFixed(2)}`
              : null}
          </BodyTextAlt>
        </div>
        {trueLineItemsBasePriceSubtotalNoDiscounts > trueLineItemsSubtotalNoDiscounts ? (
          <div className="total-item savings">
            <BodyTextAlt color="#d4212c">Savings</BodyTextAlt>
            <BodyTextAlt color="#d4212c">
              - $
              {(
                trueLineItemsBasePriceSubtotalNoDiscounts - trueLineItemsSubtotalNoDiscounts
              ).toFixed(2)}
            </BodyTextAlt>
          </div>
        ) : null}
        {trueLineItemsSubtotalNoDiscounts > trueLineItemsSubtotal ? (
          <div className="total-item savings">
            <FlexBox>
              <TagIcon
                style={{
                  width: '17px',
                  height: '17px',
                  marginRight: '5px',
                  fill: '#555',
                }}
              />{' '}
              <BodyTextAlt color="#d4212c">{discountApplications?.[0]?.code}</BodyTextAlt>
            </FlexBox>
            <BodyTextAlt color="#d4212c">
              - ${(trueLineItemsSubtotalNoDiscounts - trueLineItemsSubtotal).toFixed(2)}
            </BodyTextAlt>
          </div>
        ) : null}
      </div>
      <div className="cart-section totals">
        <div className="total-item">
          <BodyTextAlt>Subtotal</BodyTextAlt>
          <BodyTextAlt>{`$${
            trueLineItemsSubtotal || trueLineItemsSubtotal === 0
              ? trueLineItemsSubtotal.toFixed(2)
              : '0.00'
          }`}</BodyTextAlt>
        </div>
        <div className="total-item">
          <BodyTextAlt>Taxes</BodyTextAlt>
          <BodyTextAlt>${taxes.toFixed(2)}</BodyTextAlt>
        </div>
        {!isApparelOnly ? (
          <div className="total-item">
            <BodyTextAlt>Shipping</BodyTextAlt>
            <BodyTextAlt>
              {shippingSavings > 0 && shipping !== 0 && (
                <>
                  <span className="sr-only">Original price:</span>
                  <strike className="total-item__original-price">
                    ${(shipping + shippingSavings).toFixed(2)}
                  </strike>
                </>
              )}
              <span class="sr-only">Current price:</span>
              {lineItems.length > 0 && !hasOnlyGCs
                ? shipping === 0
                  ? `$35.00`
                  : `$${hasApparel ? (shipping - 9.99).toFixed(2) : shipping.toFixed(2)}`
                : '$0.00'}
            </BodyTextAlt>
          </div>
        ) : null}
        {shipping === 0 && lineItems.length > 0 && !hasOnlyGCs && (
          <div className="total-item savings">
            <BodyTextAlt>Free Shipping Savings</BodyTextAlt>
            <BodyTextAlt>- $35.00</BodyTextAlt>
          </div>
        )}
        {hasApparel ? (
          <div className="total-item">
            <BodyTextAlt>Apparel Shipping</BodyTextAlt>
            <BodyTextAlt>$9.99</BodyTextAlt>
          </div>
        ) : null}
        {giftCardPrices.giftCardSum !== undefined ? (
          <div className="total-item savings">
            <BodyTextAlt>Gift Card </BodyTextAlt>
            <BodyTextAlt>- ${giftCardPrices.giftCardSum.toFixed(2)}</BodyTextAlt>
          </div>
        ) : null}
      </div>
      <div className="cart-section full-total">
        <div className="total-item full">
          <BodyTextAlt>Total</BodyTextAlt>
          <BodyTextAlt>${lineItems.length > 0 ? `${totalPrice.toFixed(2)}` : `$0.00`}</BodyTextAlt>
        </div>

        <div className="total-item savings">
          {lineItems.length > 0
            ? trueLineItemsBasePriceSubtotalNoDiscounts - trueLineItemsSubtotal + shippingSavings >
                0 && (
                <>
                  <BodyTextAlt>Total Savings Today</BodyTextAlt>
                  <BodyTextAlt>
                    $
                    {(
                      trueLineItemsBasePriceSubtotalNoDiscounts -
                      trueLineItemsSubtotal +
                      shippingSavings
                    ).toFixed(2)}
                    !
                  </BodyTextAlt>
                </>
              )
            : null}
        </div>
      </div>

      <Footer>
        <SecureCheckout align="center">
          <Secure />
          <BodyTextAlt md="0">Secure Checkout</BodyTextAlt>
        </SecureCheckout>
        <Payments>
          <Image
            fluid={getImage(checkoutImages.edges, 'payment_icons')}
            alt="Supported Payment Methods: Visa, MasterCard, Discover, and American Express"
          />
        </Payments>
      </Footer>
    </FlexBox>
  );
};
export default CheckoutCart;
