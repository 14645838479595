import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Formik } from 'formik';
import SiteContext from '~/layouts/StoreContext';
import Loader from 'react-loader-spinner';
import isPossiblePhoneNumber from 'libphonenumber-js';
import { Link } from 'gatsby';
import AddressFormInputs from './AddressFormInputs';
import AccountModal from './AccountModal/AccountModal';
import { WhiteButton, RedButton } from '~/components/.base/buttons';
import { Subheading } from '~/components/.base/headings';
import { FlexBox } from '~/components/.base/containers';
import ArrowDownIcon from '~/images/icons/arrow_normal_down.svg';
import { css } from 'styled-components';
import './AddressForm.scss';

const initialErrors = {
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    acceptsMarketing: null,
    address1: null,
    address2: null,
    city: null,
    provinceCode: null,
    zip: null,
    countryCodeV2: null,
};

const AddressForm = ({
    customer,
    setCustomer,
    setAddressValues,
    setTaxes,
    setActivePage,
    registrationStatus,
    addressValues,
    setPaymentErrorMessage,
    totalPrice,
}) => {
    const {
        store: {
            checkout: { lineItems },
        },
    } = useContext(SiteContext);
    const [initialValues, setInitialValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        acceptsMarketing: true,
        address1: '',
        address2: '',
        city: '',
        provinceCode: '',
        zip: '',
        countryCodeV2: 'US',
    });
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [accountModalType, setAccountModalType] = useState('login');
    const [googleApiLoaded, setGoogleApiLoaded] = useState(false);
    const [hideLogin, setHideLogin] = useState(false);
    const [toggleSubmit, setToggleSubmit] = useState(false);
    const [errors, setErrors] = useState(initialErrors);

    const handleIsValidating = () => {
        setToggleSubmit(!toggleSubmit);
    };

    useEffect(() => {
        if (window && window.ga) {
            window.ga('require', 'ec');
            for (let lineItem of lineItems) {
                window.ga('ec:addProduct', {
                    name: lineItem.title,
                    id: lineItem.variant.id,
                    price: lineItem.variant.price.amount,
                    brand: 'CML',
                    variant: lineItem.variant.title,
                    quantity: lineItem.quantity,
                });
            }
            // Add the step number and additional info about the checkout to the action.
            window.ga('ec:setAction', 'checkout', {
                step: 'Address',
            });
            window.ga('send', 'event', 'Checkout', 'Address');
        }
    }, [lineItems]);
    useEffect(() => {
        if (customer) {
            setHideLogin(true);
            if (customer.defaultAddress) {
                const {
                    firstName,
                    lastName,
                    address1,
                    address2,
                    city,
                    provinceCode,
                    zip,
                    countryCodeV2,
                    phone,
                } = customer.defaultAddress;
                const values = {
                    firstName: firstName ? firstName : '',
                    lastName: lastName ? lastName : '',
                    email: customer.email ? customer.email : '',
                    phone: customer.phone ? customer.phone : phone ? phone : '',
                    acceptsMarketing: customer.acceptsMarketing,
                    address1: address1 ? address1 : '',
                    address2: address2 ? address2 : '',
                    city: city ? city : '',
                    provinceCode: provinceCode ? provinceCode : '',
                    zip: zip ? zip : '',
                    countryCodeV2: countryCodeV2 ? countryCodeV2 : 'US',
                };
                setInitialValues(values);
                setAddressValues(Object.assign({}, values));
            }
        }
    }, [customer, registrationStatus]);
    useEffect(() => {
        if (addressValues.firstName) {
            setInitialValues(addressValues);
        }
    }, []);
    const initialize = () => {
        console.log('check');
        if (window && window.google) {
            console.log('google');
            setGoogleApiLoaded(true);
        } else {
            console.log('no google');
            setTimeout(initialize, 500);
        }
    };
    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBhG2W1tn5AUfDsx3u1OLm24_4flLCUtqA&libraries=places`;
        script.addEventListener('load', initialize);
        document.body.appendChild(script);
    }, []);
    const saveAddressFn = async (values, { setSubmitting }) => {
        handleIsValidating();
        try {
            klaviyoCheckout(values);
            setAddressValues(values);
            setSubmitting(false);
            setActivePage(2);
            window.scrollTo(0, 0);
        } catch (err) {
            console.log(err.message);
            setPaymentErrorMessage(err.message);
            setSubmitting(false);
        }
    };

    const klaviyoCheckout = values => {
        // klaviyo tracking
        if (!window._learnq) {
            console.log('klaviyo');
            var s = document.createElement('script'),
                e = !document.body ? document.querySelector('head') : document.body;
            s.src = 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=WcPrXs';
            s.async = true;
            s.onload = () => {
                console.log('klaviyo loaded');
                klaviyoCheckout(values);
            };
            e.appendChild(s);
            return;
        }
        window._learnq.push([
            'identify',
            {
                $email: values.email,
                $first_name: values.firstName,
                $last_name: values.lastName,
            },
        ]);
        const klaviyoItems = [];
        const klaviyoNames = [];

        lineItems.map((lineItem, i) => {
            klaviyoNames.push(lineItem.title);
            klaviyoItems.push({
                productID: lineItem.variant.product.id,
                sku: lineItem.variant.sku,
                productName: lineItem.title,
                quantity: lineItem.quantity,
                itemPrice: parseInt(lineItem.variant.price.amount),
                rowTotal: parseInt(lineItem.variant.price.amount) * lineItem.quantity,
                productURL: `https://www.cousinsmainelobster.com/products/${lineItem.variant.product.handle}`,
                imageURL: lineItem.variant.image.src,
            });
        });
        window._learnq.push([
            'track',
            'Started Checkout',
            {
                $event_id: `${localStorage.getItem('shopify_checkout_id')}_${Math.round(
                    new Date().getTime() / 1000
                )}`,
                $value: parseInt(totalPrice),
                ItemNames: klaviyoNames,
                CheckoutURL: `https://www.cousinsmainelobster.com/cart?id=${localStorage.getItem(
                    'shopify_checkout_id'
                )}`,
                Items: klaviyoItems,
            },
        ]);
    };

    return googleApiLoaded ? (
        <Fragment>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validateOnChange={false}
                validateOnBlur={false}
                validate={values => {
                    const errs = {};
                    if (!values.countryCodeV2) {
                        errs.countryCodeV2 = 'Required';
                    }
                    if (!values.zip) {
                        errs.zip = 'Required';
                    }
                    if (!values.provinceCode) {
                        errs.provinceCode = 'Required';
                    }
                    if (!values.city) {
                        errs.city = 'Required';
                    }
                    if (!values.address1) {
                        errs.address1 = 'Required';
                    }
                    if (!values.lastName) {
                        errs.lastName = 'Required';
                    }
                    if (!values.firstName) {
                        errs.firstName = 'Required';
                    }
                    let phone = values.phone;
                    if (!phone.includes('+1')) phone = '+1' + phone;
                    if (!isPossiblePhoneNumber(phone)) {
                        errs.phone =
                            'Invalid phone number. Please use the following phone number format: XXX-XXX-XXXX';
                    }
                    if (!values.phone) {
                        errs.phone = 'Required';
                    }
                    if (values.email === '') {
                        errs.email = 'Required';
                    } else if (
                        values.email &&
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errs.email = 'Invalid email address';
                    }
                    if (registrationStatus === 'NEEDS_ACCOUNT') {
                        errs.account =
                            'Because you are purchasing a subscription, you will need to create an account to maintain it. Please create an account or log in.';
                    }

                    setErrors(errs);
                    setToggleSubmit(!toggleSubmit);
                    return errs;
                }}
                onSubmit={saveAddressFn}
            >
                {({
                    values,
                    errs,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    /* and other goodies */
                }) => (
                    <form
                        autocomplete="off"
                        className="address-form-checkout"
                        onSubmit={handleSubmit}
                    >
                        <FlexBox justify="space-between" direction="column" h="100%">
                            <div
                                css={css`
                                    @media screen and (min-width: 991px) {
                                        flex: 1;
                                        overflow: scroll;
                                        padding: 1rem 2.5rem 0;
                                    }
                                `}
                            >
                                <AddressFormInputs
                                    addressValues={addressValues}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    touched={touched}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    registrationStatus={registrationStatus}
                                    setModalIsOpen={setModalIsOpen}
                                    setAccountModalType={setAccountModalType}
                                    hideLogin={hideLogin}
                                    toggleSubmit={toggleSubmit}
                                />
                            </div>
                            <FlexBox className="form-footer" align="center" justify="space-between">
                                <FlexBox
                                    as={Link}
                                    to="/cart"
                                    align="center"
                                    className="back-button desktop"
                                >
                                    <WhiteButton as="span" w="50px" h="50px" pd="0px">
                                        <ArrowDownIcon
                                            style={{
                                                transform: 'rotate(90deg)',
                                            }}
                                        />
                                    </WhiteButton>
                                    <Subheading as="span" md="0rem 0rem 0rem .75rem">
                                        Return to cart
                                    </Subheading>
                                </FlexBox>
                                <div className="mobile">
                                    <WhiteButton as={Link} to="/cart" className="back-button">
                                        Return to cart
                                    </WhiteButton>
                                </div>
                                <div className="button-bg" />
                                <RedButton
                                    type="submit"
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                    h="50px"
                                    pd="0 35px"
                                >
                                    {isSubmitting ? (
                                        <Fragment>
                                            <Loader
                                                type="TailSpin"
                                                color="#152622"
                                                height={16}
                                                width={16}
                                            />
                                            Loading...
                                        </Fragment>
                                    ) : (
                                        'Continue to shipping'
                                    )}
                                </RedButton>
                            </FlexBox>
                        </FlexBox>
                    </form>
                )}
            </Formik>
            {!hideLogin ? (
                <AccountModal
                    modalIsOpen={modalIsOpen}
                    setModalIsOpen={setModalIsOpen}
                    accountModalType={accountModalType}
                    setAccountModalType={setAccountModalType}
                    setCustomer={setCustomer}
                />
            ) : null}
        </Fragment>
    ) : null;
};

export default AddressForm;
